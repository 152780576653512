import React, { Component } from "react";

export default class radiotest1 extends Component {
  state = { selectedOption: "no" };

  handleOptionChange = e => {
    if (e.target.checked && e.target.value === "yes") { // if value of "No" option is selected
      this.props.history.push("radiogaga"); // navigate to another route
      //window.location.href = "https://www.google.com"; - if you want to navigate to another page
    }
    this.setState({ selectedOption: e.target.value });
  };

  render() {
    const { selectedOption } = this.state;
    const { history } = this.props;

    return (
      <div>
        
         <div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="yes"
              checked={selectedOption === "yes"}
              onChange={this.handleOptionChange}
            />
            Yes
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              
              checked={selectedOption === "no"}
              onChange={this.handleOptionChange}
            />
            No
          </label>
        </div>
      </div>
          
      </div>
    );
  }
}
