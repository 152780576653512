
import ReactDOM from 'react-dom';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBContainer,MDBRow,MDBCol,MDBIcon } from "mdbreact";
import React, { Component } from 'react';

import NBar from '../components/Home/Small/NBar';
import FooterPagePro from './FooterPagePro';

import './Contactus.css';
class Nearby extends Component {
  
    render() {
      
        return (
<div >
      <NBar/>

        <center><h2 className="again">Contact Details here</h2></center>
        <br/><br/>
        <center><MDBContainer className="dropl">
      <MDBRow>
        <MDBCol md="4" className="border">
            <center><MDBIcon icon="home" size="3x"/><br/><br/>
            <h4>Visit US</h4><br/>
            <p>Kolhapur</p></center>
        </MDBCol>
        <MDBCol md="4" className="border">
            <center><MDBIcon icon="phone" size="3x"/><br/><br/>
            <h4>Call Us</h4><br/>
            <p>+91-9869986966</p></center>
        </MDBCol>
        <MDBCol md="4" className="border">
            <center><MDBIcon icon="envelope" size="3x"/><br/><br/>
              <h4>Contact US</h4><br/>
              <p>@emanjha.net</p></center>
        </MDBCol>
      </MDBRow>
    </MDBContainer></center>
          </div>
        );
    }
}

export default Nearby;


 