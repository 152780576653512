import ReactDOM from 'react-dom';
import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBCol,MDBRow,MDBContainer } from "mdbreact";
import React, { Component } from 'react';
class ActivityPage extends Component {
   constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }

    componentDidMount() {
      //const {id1} =this.props.match.params.id1;
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
        fetch(`https://emanjhaapi.com/locapi/get_location/${this.props.vati}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
    render() {
      var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }

       
      return (
        <div className="title">
        <div className="HideOnMob">
            <InfiniteCarousel breakpoints={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,

                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
            ]}
            dots={true}
            showSides={false}
            sidesOpacity={.5}
            sideSize={.1}
            slidesToScroll={4}
            slidesToShow={7}
            scrollOnDevice={true}
            arrows={true}>

             {this.state.items.activities.map(item => (
                    <div>
                    
                    <img className="img-fluid" alt='' src={item.activity_img} waves style={{height:"10vw",width:"15rem"}}/>
                <a href={`/activity/${item.activity_name}/${item.activity_id}`}><h4 className="quick">{item.activity_name}</h4></a>
                     </div>                      

                        ))}
               
                  
          </InfiniteCarousel>
         </div>
         <div className="ShowOnMob">
           <MDBContainer className="m-0 p-0 koi ">
           <MDBRow className="mt-1">
               {this.state.items.activities.map(item=>( 
                 
                 <MDBCol size="5" className="mt-1 ml-3 mr-2 p-0"><img className="rounded-circle border border-dark p-1 mr-1" style={{height:"2rem",width:"2rem"}} src={item.activity_img}/><b><a href={`/activity/${item.activity_id}`}><span className="black-text">{item.activity_name}</span></a></b></MDBCol>
              
                 ))}
                
               </MDBRow>
                  
         </MDBContainer>
         </div>

          
      </div>
        );
    }
}

export default ActivityPage;
