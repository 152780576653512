import React, { Component } from 'react';
import NBar from "../NBar";
import { MDBMedia } from 'mdbreact';

class MuseumVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        items: [],
        isLoaded: false
        //id2:this.props.match.params.id1
    }
}
componentDidMount() {
  //const { match :{ params } } = this.props;
  //let id2=this.props.match.params.id1;
 // const {n15} =this.props.match.params.n15;
    fetch(`http://159.89.175.187/locapi/get_loc_activity/5`)
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaded: true,
                items: json
            })
        });
}

      render() {
       
        //  let n15=this.props.match.params.n15;
          var { isLoaded, items } = this.state;
              if (!isLoaded) {
                  return <div>Loading...</div>;
              }
           return (
            <div  >
           <NBar />
           {items.map(item=> (
           <MDBMedia list className="mt-3">
      <MDBMedia tag="li">
        <MDBMedia left href={`/${item.location_country}/${item.location_state}/${item.location_id}`}>
          <MDBMedia  object src={item.location_images.fimg} alt="Generic placeholder image" style={{height:"8vw" ,width:"8vw"}}/>
         
        </MDBMedia>
        <MDBMedia body>
          <MDBMedia heading>
        {item.location_name}
          </MDBMedia>
          </MDBMedia>
          
      </MDBMedia>
     
     
    </MDBMedia> ))}
        
           
        
            </div>
        );
    }
}

export default MuseumVisit;  