
import ReactDOM from 'react-dom';
import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBContainer,MDBRow,MDBCol } from "mdbreact";
import {Row,Col,Container,MDBCollapse} from 'mdbreact';
import React, { Component } from 'react';
import './ActivityHome.css';

class ActivityHome extends Component {
  constructor(props){
    super(props);
    this.state={
      items:[],
      isLoaded:false
    }
  }

componentDidMount(){
  fetch(`https://emanjhaapi.com/activities/`)
    .then(res=>res.json())
    .then(json=>{
      this.setState({
        isLoaded:true,
        items:json
      })
    });
}
 state = {
  collapseID: ""
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));
}
    render() {
      var { isLoaded, items } = this.state;
              if (!isLoaded) {
                  return <div>Loading...</div>;
              }
        return (
<div className="title">
  
          <div className="HideOnMob ">
            <Container fluid>
              <Row>
                {items.slice(0,16).map(item=> (            
                <center><Col>
                  <div>
                    <img  className="img-fluid ciga" alt='' src={item.activity_img} waves style={{height:"9vw",width:"9rem"}}/>
                    <h4 className="quick"><a href={`/activity/${item.activity_name}/${item.activity_id}`}>{item.activity_name}</a></h4>             
                  </div>
                </Col></center>
                ))}
              </Row>
              
              <br/>
              <a color="primary" onClick={this.toggleCollapse("basicCollapse")} style={{ marginBottom: "1rem" }}>
          Show more
        </a>
          <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
            <Row>
                {items.slice(16,40).map(item=> (            
                <center><Col>
                  <div style={{width:"9rem"}}>
                    <img  className="img-fluid ciga" alt='' src={item.activity_img} waves style={{height:"9vw"}}/>
                    <h4 className="quick"><a href={`/activity/${item.activity_name}/${item.activity_id}`}>{item.activity_name}</a></h4>             
                  </div>
                </Col></center>
                ))}
              </Row>
              <br/>
             
          </MDBCollapse>               
            </Container>
            
            
          </div>



          <div className="ShowOnMob kolbi">
          
        <MDBContainer className="m-0 p-0 koi ">
           <MDBRow className="mt-1">
               {items.map(item=>( 
                 
                 <MDBCol size="5" className="mt-1 ml-1 mr-1 p-0"><img className="rounded-circle border border-dark p-1 mr-1 intern1" style={{height:"2rem",width:"2rem"}} src={item.activity_img}/><b><a href={`/activity/${item.activity_name}/${item.activity_id}`}><p className="black-text intern">{item.activity_name}</p></a></b></MDBCol>
              
                 ))}
                
               </MDBRow>
                  
         </MDBContainer>        

        </div>
          </div>
        );
    }
}

export default ActivityHome;


 