import React from 'react';
import './Weatherchotu.css'
class Weatherchotu extends React.Component{
	 constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
	 componentDidMount() {
    	
        fetch(`https://api.apixu.com/v1/current.json?key=8dbe3361b0e54bcc808191248190903&q=${this.props.pathare1}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
	
	render(){
		var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
		<div className="container athvan">
			<marquee behavior="scroll" direction="left"><b>Temp</b>:&nbsp;{items.current.temp_c}&deg;C &nbsp;&nbsp;<b>Condition</b>:&nbsp;{items.current.condition.text}&nbsp;&nbsp; <b>wind</b>:{items.current.wind_kph}&nbsp;&nbsp;<b>Humidity</b>:&nbsp;{items.current.humidity} </marquee>
                
                  
	
                
        </div>

			)
	}
}

export default Weatherchotu;