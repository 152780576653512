import React, { Component } from 'react';
 import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 import {MDBContainer} from 'mdbreact';
 import './Maps.css'
 const style = {
  width: '80%',
  height: '550px',
  padding:'10px'
  
}
class Maps extends Component {
  

  render() {
    return (
      // Important! Always set the container height explicitly
      <center className="mr-5">
      <MDBContainer className="wish">
        <Map
          google={this.props.google}
          style={style}
          initialCenter={{
            lat: `${this.props.rajdhani1}`,
            lng: `${this.props.rajdhani2}`
          }}
          zoom={15}
          onClick={this.onMapClicked}>
          <Marker
    title={this.props.rajdhani3}
    name={'bhakti'}
    position={{lat: `${this.props.rajdhani1}`, lng: `${this.props.rajdhani2}`}} />

        </Map>
      </MDBContainer>
      </center>
    );
  }
}
 export default GoogleApiWrapper({
  apiKey: ("AIzaSyAopdFmJshDmxxMbc0fLPerlQo1PZYvC6E")
})(Maps)

