import React, { Component } from 'react';
import {Button,ModalHeader,ModalBody,ModalFooter,Modal,Container} from 'mdbreact';
import { Provider } from 'react-redux';

import Loginscreen from './Loginscreen';
import store from './store';
import "./Modaly.css";


class Modaly extends Component {
    
constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


    render() {
        return (
<Container>
        <a className="text-white" onClick={this.toggle}>Login</a>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="Modal_header" toggle={this.toggle}></ModalHeader>
          <ModalBody className="Modal_body">
            <Provider className="Modal_body" store={store}>
            <Loginscreen />
        </Provider>
          </ModalBody>
          <ModalFooter className="Modal_footer">
            
          </ModalFooter>
        </Modal>
      </Container>
      );
    }
}
  export default Modaly;