import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {Navbar, NavbarBrand, NavbarToggler,Collapse,NavbarNav,NavItem,MDBNavLink,MDBContainer,MDBCol,MDBRow} from 'mdbreact';
import {Carousel,CarouselInner,CarouselItem,View,Mask} from 'mdbreact';
import {Button,Jumbotron} from 'mdbreact';
import {MDBBadge,MDBIcon} from 'mdbreact';
import Reviews from './Reviews';
import './MainPage2.css';
import './MainPage4.css';
import NBar from '../../components/NBar';
import Weatherme2 from '../../components/Weatherme2';
import Weatherme3 from '../../components/Weatherme3';
import Weatherchotu from '../../components/Weatherchotu';
import { Link, animateScroll as scroll } from "react-scroll";
import Nearby from './Nearby';
import ActivityPage from './ActivityPage';


import ReactDOM from 'react-dom';
class MainPage4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: {},
            isLoaded: false
        }
    }

    componentDidMount() {
    	//const {id1} =this.props.match.params.id1;
    	//const { match :{ params } } = this.props;
    	//let id2=this.props.match.params.id1;
        fetch(`https://emanjhaapi.com/locapi/get_location/5`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
                console.log(json);
            });
    }

    render() {
       	//let id1=this.props.match.params.id1;
         
         
         var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return (
            <div>              
              <Router>              
                <NBar/>
              </Router>
             <div className="cont12 container">
                 <div>
            
                {this.state.items.activities.map(item => (

                    <div className="ajnabe mt-1 ml-2 mr-2 p-1">
                    {/*<img className="scars" style={{width:"4rem",height:"4rem"}} src={item.activities.fimg}/>*/}
                    <a className="black-text" href="#"><p className="scars1 ">{item.activity_img}{item.activity_name}{item.activity_id}</p></a>
                    </div>

                        ))}
            
            </div>

                   </div>
             

      </div>
        );
    }
}
export default MainPage4;	
