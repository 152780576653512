import React from "react";
import {MDBBtn, MDBBtnGroup} from "mdbreact";
import {Card,CardImage,CardBody,CardTitle,CardText,Fa,Button,CardGroup} from 'mdbreact';
import { Container, Row, Col, Input } from 'mdbreact';
import NBar from '../components/Home/Small/NBar';
import {BrowserRouter as Router,Route,Link} from "react-router-dom";
import './abcd99.css';
import FooterPagePro from './FooterPagePro';
class abcdus extends React.Component{
	constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
    	//const { match :{ params } } = this.props;
    	//let id2=this.props.match.params.id1;
    	const {n3} =this.props.match.params.n3;
    	
        fetch(`https://emanjhaapi.com/locapi/alphabet_c/United States/${this.props.match.params.n3}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }


	render(){
		
		let n3=this.props.match.params.n3;
		var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
			<div className="samzha">
				 
               <Router>              
                <NBar/>
           		</Router>

           <div className="d-flex justify-content-center">

          
         <h4><b>Find places in USA</b></h4> 
         </div>
         	
          <center><MDBBtn href="/abcdin/a" size="sm"  color="elegant-color" className="elegant-color">Explore India</MDBBtn></center>
          <br/>
        
			
<div className="container-fluid  HideOnMob stayaway">
			<MDBBtnGroup className="d-flex justify-content-center flex-wrap ">
			    <MDBBtn href="/abcdus/a" size="sm" color="elegant-color" className="butaplha elegant-color">a</MDBBtn>
			    <MDBBtn href="/abcdus/b" size="sm" color="elegant-color" className="butaplha elegant-color">b</MDBBtn>		    
			    <MDBBtn href="/abcdus/c" size="sm" color="elegant-color" className="butaplha elegant-color">c</MDBBtn>
			    <MDBBtn href="/abcdus/d" size="sm" color="elegant-color" className="butaplha elegant-color">d</MDBBtn>
			    <MDBBtn href="/abcdus/e" size="sm" color="elegant-color" className="butaplha elegant-color">e</MDBBtn>
			    <MDBBtn href="/abcdus/f" size="sm" color="elegant-color" className="butaplha elegant-color">f</MDBBtn>
			    <MDBBtn href="/abcdus/g" size="sm" color="elegant-color" className="butaplha elegant-color">g</MDBBtn>
			    <MDBBtn href="/abcdus/h" size="sm" color="elegant-color" className="butaplha elegant-color">h</MDBBtn>
			    <MDBBtn href="/abcdus/i" size="sm" color="elegant-color" className="butaplha elegant-color">i</MDBBtn>
			    <MDBBtn href="/abcdus/j" size="sm" color="elegant-color" className="butaplha elegant-color">j</MDBBtn>
			    <MDBBtn href="/abcdus/k" size="sm" color="elegant-color" className="butaplha elegant-color">k</MDBBtn>
			    <MDBBtn href="/abcdus/l" size="sm" color="elegant-color" className="butaplha elegant-color">l</MDBBtn>
			    <MDBBtn href="/abcdus/m" size="sm" color="elegant-color" className="butaplha elegant-color">m</MDBBtn>
			    <MDBBtn href="/abcdus/n" size="sm" color="elegant-color" className="butaplha elegant-color">n</MDBBtn>
			    <MDBBtn href="/abcdus/o" size="sm" color="elegant-color" className="butaplha elegant-color">o</MDBBtn>
			    <MDBBtn href="/abcdus/p" size="sm" color="elegant-color" className="butaplha elegant-color">p</MDBBtn>
			    <MDBBtn href="/abcdus/q" size="sm" color="elegant-color" className="butaplha elegant-color">q</MDBBtn>
			    <MDBBtn href="/abcdus/r" size="sm" color="elegant-color" className="butaplha elegant-color">r</MDBBtn>
			    <MDBBtn href="/abcdus/s" size="sm" color="elegant-color" className="butaplha elegant-color">s</MDBBtn>
			    <MDBBtn href="/abcdus/t" size="sm" color="elegant-color" className="butaplha elegant-color">t</MDBBtn>
			    <MDBBtn href="/abcdus/u" size="sm" color="elegant-color" className="butaplha elegant-color">u</MDBBtn>
			    <MDBBtn href="/abcdus/v" size="sm" color="elegant-color" className="butaplha elegant-color">v</MDBBtn>
			    <MDBBtn href="/abcdus/w" size="sm" color="elegant-color" className="butaplha elegant-color">w</MDBBtn>
			    <MDBBtn href="/abcdus/x" size="sm" color="elegant-color" className="butaplha elegant-color">x</MDBBtn>
			    <MDBBtn href="/abcdus/y" size="sm" color="elegant-color" className="butaplha elegant-color">y</MDBBtn>
			    <MDBBtn href="/abcdus/z" size="sm" color="elegant-color" className="butaplha elegant-color">z</MDBBtn>
  			</MDBBtnGroup>
  	</div>

  	<div className="container-fluid ShowOnMob">
<ul className="mumbai pagination justify-content-center">
    <li className="zombie border border-dark rounded-left "><a className="white-text" href="/abcdus/a">a</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/b">b</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/c">c</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/d">d</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/e">e</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/f">f</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/g">g</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/h">h</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/i">i</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/j">j</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/k">k</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/l">l</a></li>
    <li className="zombie border border-dark rounded-right"><a className="white-text" href="/abcdus/m">m</a></li>
    
   </ul>
   <ul className="mumbai pagination justify-content-center">
   	<li className="zombie border border-dark rounded-left"><a className="white-text" href="/abcdus/n">n</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/o">o</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/p">p</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/q">q</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/r">r</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/s">s</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/t">t</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/u">u</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/v">v</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/w">w</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/x">x</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdus/y">y</a></li>
    <li className="zombie border border-dark rounded-right "><a className="white-text" href="/abcdus/z">z</a></li>
  </ul>
</div>




  
  			<br/>
  			<Row className="onemore" style={{ display:"inline-block",width:"100%", height:"auto" }}>
  			{items.map(item=> (

			<Col key={item.location_id} className="mt-4 needmoney HideOnMob">
                <Card className="mx-auto pl-0.5" style={{ width: "16rem", height:"13rem" }}>
                  <CardImage className="img-fluid" style={{height:"10rem", width:"100%"}}src={item.location_images.fimg} waves/>
                  <CardBody  className="elegant-color white-text" >
                    <CardTitle><a className="white-text" href={`/United States/${item.location_state}/${item.location_id}`}><p style={{textTransform:'uppercase'}}className="mahi">{item.location_name}</p></a></CardTitle>
                  </CardBody>
                </Card><br/><br/>
              </Col> ))}</Row>
			


  			<div className="ShowOnMob">
			
				{items.map(item => (

					<div key={item.location_id} className="ajnabe mt-1 ml-2 mr-2 p-1">
					<img className="scars" style={{width:"4rem",height:"4rem"}} src={item.location_images.fimg}/>
					<a className="black-text" href={`/United States/${item.location_state}/${item.location_id}`}><p className="scars1 ">{item.location_name}</p></a>
					</div>
          
						))}
			
			</div>

     
			
     
			
<FooterPagePro/>
			</div>




			);
	}
}

export default abcdus