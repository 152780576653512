
import ReactDOM from 'react-dom';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBCollapse } from "mdbreact";
import React, { Component } from 'react';
import './Nearby.css'
class Nearby extends Component {
  constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
      
      
        fetch(`https://emanjhaapi.com/locapi/nearby_locations/${this.props.haarke}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
     state = {
  collapseID: ""
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));
}

    render() {
      var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return (
<div className="title">
  
      <div className="HideOnMob">
            <InfiniteCarousel
            breakpoints={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
            ]}
            dots={false}
            showSides={false}
            sidesOpacity={.5}
            sideSize={.1}
            slidesToScroll={4}
            slidesToShow={4}
            scrollOnDevice={true}
            arrows={true}
          >
          
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[1].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
                <a href={`/${items.nearby_locs[1].country}/${items.nearby_locs[1].state}/${items.nearby_locs[1].id}`}><p className="hastarcacg" className="hastarcacg">{items.nearby_locs[1].name}</p></a>
              <p className="hastarcacg">({items.nearby_locs[1].distance_ml} miles)</p>
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[2].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
              <a href={`/${items.nearby_locs[2].country}/${items.nearby_locs[2].state}/${items.nearby_locs[2].id}`}><p className="hastarcacg">{items.nearby_locs[2].name}</p></a>
              <p className="hastarcacg">({items.nearby_locs[2].distance_ml} miles)</p>
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[3].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
              <a href={`/${items.nearby_locs[3].country}/${items.nearby_locs[3].state}/${items.nearby_locs[3].id}`}><p className="hastarcacg">{items.nearby_locs[3].name}</p></a>  
              <p className="hastarcacg">({items.nearby_locs[3].distance_ml} miles)</p>           
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[4].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
              <a href={`/${items.nearby_locs[4].country}/${items.nearby_locs[4].state}/${items.nearby_locs[4].id}`}><p className="hastarcacg">{items.nearby_locs[4].name}</p></a>
              <p className="hastarcacg">({items.nearby_locs[4].distance_ml} miles)</p>              
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[5].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
              <a href={`/${items.nearby_locs[5].country}/${items.nearby_locs[5].state}/${items.nearby_locs[5].id}`}><p className="hastarcacg">{items.nearby_locs[5].name}</p></a> 
              <p className="hastarcacg">({items.nearby_locs[5].distance_ml} miles)</p>            
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[6].img.fimg} waves style={{height:"15vw",width:"19rem"}}/> 
              <a href={`/${items.nearby_locs[6].country}/${items.nearby_locs[6].state}/${items.nearby_locs[6].id}`}><p className="hastarcacg">{items.nearby_locs[6].name}</p></a> 
              <p className="hastarcacg">({items.nearby_locs[6].distance_ml} miles)</p>             
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[7].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
              <a href={`/${items.nearby_locs[7].country}/${items.nearby_locs[7].state}/${items.nearby_locs[7].id}`}><p className="hastarcacg">{items.nearby_locs[7].name}</p></a> 
              <p className="hastarcacg">({items.nearby_locs[7].distance_ml} miles)</p>             
            </div>
            <div>
              <img className="img-fluid" alt='' src={items.nearby_locs[8].img.fimg} waves style={{height:"15vw",width:"19rem"}}/>
              <a href={`/${items.nearby_locs[8].country}/${items.nearby_locs[8].state}/${items.nearby_locs[8].id}`}><p className="hastarcacg">{items.nearby_locs[8].name}</p></a>  
              <p className="hastarcacg">({items.nearby_locs[8].distance_ml} miles)</p>           
            </div>
            
          </InfiniteCarousel>
        </div>
        <div className="ShowOnMob">
            <div className="ShowOnMob kolbi">
            
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[1].img.fimg}/>
              <a className="black-text" href={`/${items.nearby_locs[1].country}/${items.nearby_locs[1].state}/${items.nearby_locs[1].id}`}><b><span className="scars1home">{items.nearby_locs[1].name} ({items.nearby_locs[1].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[2].img.fimg}/>
              <a className="black-text" href={`/${items.nearby_locs[2].country}/${items.nearby_locs[2].state}/${items.nearby_locs[2].id}`}><b><span className="scars1home">{items.nearby_locs[2].name} ({items.nearby_locs[2].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[3].img.fimg}/>
              <a className="black-text" href={`/${items.nearby_locs[3].country}/${items.nearby_locs[3].state}/${items.nearby_locs[3].id}`}><b><span className="scars1home">{items.nearby_locs[3].name} ({items.nearby_locs[3].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[4].img.fimg}/>
              <a className="black-text" href={`/${items.nearby_locs[4].country}/${items.nearby_locs[4].state}/${items.nearby_locs[4].id}`}><b><span className="scars1home">{items.nearby_locs[4].name} ({items.nearby_locs[4].distance_ml} mi)</span></b></a>
            </div>
                       
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[5].img.fimg}/>
              <a className="black-text" href={`/${items.nearby_locs[5].country}/${items.nearby_locs[5].state}/${items.nearby_locs[5].id}`}><b><span className="scars1home">{items.nearby_locs[5].name} ({items.nearby_locs[1].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[6].img.fimg}/>
              <a className="black-text" href={`/${items.nearby_locs[6].country}/${items.nearby_locs[6].state}/${items.nearby_locs[6].id}`}><b><span className="scars1home">{items.nearby_locs[6].name} ({items.nearby_locs[2].distance_ml} mi)</span></b></a>
            </div>
        
        </div>
         </div>
        
          </div>
        );
    }
}

export default Nearby;


 