import ReactDOM from 'react-dom';
import { Container, Row, Col, Input,Card,CardImage,CardBody,CardTitle } from 'mdbreact';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBContainer,MDBRow,MDBCol } from "mdbreact";
import React, { Component } from 'react';
import './ActivityHome.css';
import NBar from "../../components/Home/Small/NBar";
import FooterPagePro from '../../components/FooterPagePro';


class Apidata extends Component {

    constructor(props){
      super(props);
      this.state={
        items:null,
        isLoaded:false
      
      }
    }
  
 async  componentDidMount(){
    const url='https://developer.nps.gov/api/v1/parks?parkCode=acad&api_key=o2W3ZuxbhAVR5u5HfU9wPISaaC5GhVdmHL9XhD2i';
     const response=await fetch(url);
        const data = await response.json();
        console.log(data.data);
        console.log(data.data["0"].designation);
        console.log(data.data["0"].directionsInfo);
       // document.getElementById('getText').innerHTML=data.data["0"].description;
     
            
  }
       render() {
         var { isLoaded ,data} = this.state;
              if (!isLoaded) {
                   return <div>Loading...</div>;
              }
                return (
               
                    <div>
                      

                        <div id ="getText">
                     
                       {data.total.data[0].designation}
                        </div>
                         </div>
                          
            
                        
               
                            );
                        }
                    }
                    
                    export default Apidata;



                    