import React, { Component } from 'react';
import NBar from "../Home/Small/NBar";
import { MDBMedia } from 'mdbreact';
import {Card,CardImage,CardBody,CardTitle,CardText,Fa,Button,CardGroup,MDBBtn} from 'mdbreact';
import FooterPagePro from '../../components/FooterPagePro';

import { Container, Row, Col, Input } from 'mdbreact';
import './Surfing.css'

class PlacesGo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        items: [],
        isLoaded: false
        //id2:this.props.match.params.id1
    }
}
componentDidMount() {
  //const { match :{ params } } = this.props;
  //let id2=this.props.match.params.id1;
 // const {n15} =this.props.match.params.n15;
    fetch(`https://emanjhaapi.com/locapi/search2/United States/tag/${this.props.match.params.n3}/`)
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaded: true,
                items: json
            })
        });
}

      render() 
        {
       
        //  let n15=this.props.match.params.n15;
          var { isLoaded, items } = this.state;
              if (!isLoaded) {
                  return <div>Loading...</div>;
              }

           return (
            <div >
           <NBar/>
           <br/>
           &nbsp;<h2>{this.props.match.params.n3} in USA</h2>
        

<MDBBtn href={`/cat/India/${this.props.match.params.n3}`} size="sm"  color="elegant-color" className="elegant-color">Go to India</MDBBtn>

<br/><br/>




           <Row className="onemore mt-3" style={{ display:"inline-block",width:"100%", height:"auto" }}>
        {items.map(item=> (

      <Col className="mt-4 asdss needmoney HideOnMob">
                <Card className="mx-auto" style={{ width: "16rem", height:"13rem" }}>
                  <CardImage className="img-fluid" style={{height:"10rem", width:"100%"}} src={item.location_images.fimg} waves/>
                  <CardBody  className="elegant-color white-text">
                    <CardTitle><a className="white-text" href={`/${item.location_country}/${item.location_state}/${item.location_id}`}>{item.location_name}</a></CardTitle>
                  </CardBody>
                </Card><br/><br/>
              </Col> ))}</Row>

        <div className="ShowOnMob">
      {items.map(item => (

          <div className="border">
          <img style={{width:"4rem",height:"4rem"}} src={item.location_images.fimg}/>
          &nbsp;<a href={`/${item.location_country}/${item.location_state}/${item.location_id}`}>{item.location_name}</a>
          </div>

            ))}
      </div>
        <FooterPagePro/>
           
        
            </div>
        );
    }
}

export default PlacesGo;  