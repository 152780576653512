import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

import './Login.css';
import { MDBBtn } from "mdbreact";

class Login extends React.Component{
	constructor() {
	    super();
	    this.state = {
	    	data: {
	    		success: ''
			},
			isVerified: false
	    }
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.checkLogin = this.checkLogin.bind(this);
		this.divert = this.divert.bind(this);
		this.verifyCallback = this.verifyCallback.bind(this);
		this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
	}
	
	recaptchaLoaded() {
		console.log('capcha successfully loaded');
	  }
	
	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);

		fetch('https://emanjhaapi.com/emanjhaapi/check_login/', {
		  method: 'POST',
		  body: data,
		})
		.then(response => response.json())
		.then((responseJson) => {
			this.checkLogin(responseJson);
		});
	}

	checkLogin(responseJson){
		if(responseJson.success == "true"){
			alert("Login Successful, Welcome "+responseJson.user.user_fname);
			sessionStorage.setItem('login', 'true');
			sessionStorage.setItem('user_id', responseJson.user.user_id);
			sessionStorage.setItem('user_name', responseJson.user.user_fname + " " + responseJson.user.user_lname);	
			//this.props.history.push('/Home1');
			window.location.href='https://www.travelasugo.com/abcdus/b';
		
		}
		else{
			alert("False");
		}
	}

	divert(){
		
	}

	verifyCallback(response) {
		if (response) {
		  this.setState({
			isVerified: true
		  })
		}
	  }

	

	render() {
	    return (
           
            <div>
            <h3>Login</h3>    
            <form onSubmit={this.handleSubmit}>
            <div className="form-group">
            <label htmlFor="formGroupExampleInput">Email</label>
            <input
            className="form-control"
              id="formGroupExampleInput" type="email" placeholder="Enter email" size="sm" name="user_email" autocomplete="off" required
            />
             <label htmlFor="formGroupExampleInput">Password</label>
            <input
              className="form-control"
              id="formGroupExampleInput"  type="password" placeholder="Password" size="sm" name="user_password" autocomplete="off" required
            />
			
							<Recaptcha
					sitekey="6Lcwh5sUAAAAALF_kecy-hZyMCf1B4eh3RywNAr5"
					render="explicit"
					verifyCallback={this.verifyCallback}
					onloadCallback={this.recaptchaLoaded}
				/>
			
            <MDBBtn color="primary" type="submit" >Login</MDBBtn>
		
          </div>
          </form>
				</div>
			

				
	    );
	}
}

export default Login;  