import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import ReactTimeout from 'react-timeout';
import {MDBContainer,MDBCol,MDBRow} from 'mdbreact';
import './godfather.css'
class Review extends React.Component{
	constructor() {
	    super();
	    this.state = {
	    	data: [],
	    	loc_id: 5,
	    	user_id: 0,
	    	success: "Not Yet"
	    }
	    this.handleSubmit = this.handleSubmit.bind(this);

	}

	componentDidMount(){

		const login_details = sessionStorage.getItem('login');

	    if(login_details){
	    	this.setState({user_id: sessionStorage.getItem('user_id')}) 
	    }
	    else{
	    	this.setState({user_id: 0}) 
	    }



		fetch('https://emanjhaapi.com/locapi/get_comments/'+this.props.ankh, {
		  method: 'GET'
		})
		.then(response => response.json())
		.then(data => this.setState({data}));
	}

	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);

		fetch('https://emanjhaapi.com/locapi/add_comment/', {
		  method: 'POST',
		  body: data,
		})
		.then(response => response.json())
		.then((responseJson) => {
			fetch('https://emanjhaapi.com/locapi/get_comments/'+this.props.ankh, {
			  method: 'GET'
			})
			.then(response => response.json())
			.then(data => this.setState({data})); 
		});
	}


	render() {
	    return (
	    	<div class="container">
		    
		    <div class="row">
		    <div class="col">
	<MDBContainer className="HideOnMob">
		{
		  this.state.data.map(function(comment){
					    	return(
      <MDBRow className='pahayche mt-1'>
        <MDBCol md="2" className="mt-3"><img src="https://i.ibb.co/gTT0GkL/Capture.png" style={{height:'50px'}} className="img-fluid z-depth-1 rounded-circle" alt="" /><p><b>{comment.user_name}</b></p></MDBCol>
        <MDBCol md="10" className="mt-3"><span><b>Rating:</b> {comment.comment.comment_stars}/5</span>
		  <br/><p>{comment.comment.comment_text}</p></MDBCol>
      </MDBRow>
         	);
		})
	}     

    </MDBContainer>

    <MDBContainer className="ShowOnMob">
		{
		  this.state.data.map(function(comment){
					    	return(
      <MDBRow className='pahayche mt-1'>
        <MDBCol size="3" className="mt-3"><img src="https://i.ibb.co/gTT0GkL/Capture.png" style={{height:'50px'}} className="img-fluid z-depth-1 rounded-circle" alt="" /><p className="smalltext"><b>{comment.user_name}</b></p></MDBCol>
        <MDBCol size="8" className="mt-3"><span><b>Rating:</b> {comment.comment.comment_stars}/5</span>
		  <br/><p className="smalltext1">{comment.comment.comment_text}</p></MDBCol>
      </MDBRow>
         	);
		})
	}     

    </MDBContainer>
</div>
    <div class="col" className="heathen mt-2 mr-2" md="10"> 

    <h4>Write your own reviews here</h4>
    <form onSubmit={this.handleSubmit}>
			        <Form.Group controlId="exampleForm.ControlSelect1">
				    <Form.Label >Rate the Location</Form.Label>
					    <Form.Control className="chop" as="select" name="comment_stars">
					      <option>1</option>
					      <option>2</option>
					      <option>3</option>
					      <option>4</option>
					      <option>5</option>
					    </Form.Control>
				  	</Form.Group>

					<Form.Group controlId="exampleForm.ControlTextarea1">
					    <Form.Label>Enter Review</Form.Label>
					    <Form.Control className="chop" as="textarea" rows="3" name="comment_text" />
					 </Form.Group>

					 <input type="hidden" value={this.props.ankh} name="loc_id" />
					 <input type="hidden" value={this.state.user_id} name="user_id" />

					  <Button variant="primary" type="submit">
					    Submit
					  </Button>
			      </form>

				
</div>
</div>

		  </div>
	    );
	}
}

export default Review;  