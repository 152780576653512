import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBContainer }
from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';
import Collapsible from 'react-collapsible';

class Alert2 extends Component {
state = {
collapseID: ''
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
}
handleClick() {
  this.setState({
      open: !this.state.open
  });
}

render() {
  return (
    <div>
    <Router>
      
        <MDBNavbar color="black"  >
          <MDBContainer>
            <MDBNavbarBrand className="white-text">
              Alert
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.toggleCollapse('navbarCollapse11')} >
                      <HamburgerMenu
              isOpen={this.state.open}
              
              menuClicked={this.handleClick.bind(this)}
              width={18}
              height={15}
              strokeWidth={1}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
          />
        </MDBNavbarToggler>
            <MDBCollapse id="navbarCollapse11" isOpen={this.state.collapseID} navbar>
              <MDBNavbarNav left>
                <MDBNavItem active>
                  <MDBNavLink to="#!">Starting Mon Aug 19, a new culvert will be installed at Intersection 2 of the park carriage road system, located between Hulls Cove Visitor Center and Witch Hole Pond. The site will remain open for visitors during construction.</MDBNavLink>
                </MDBNavItem>
               
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
       
    </Router>
    
    </div>
    
    );
  }
}

export default Alert2;