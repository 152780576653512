import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {Navbar, NavbarBrand, NavbarToggler,Collapse,NavbarNav,NavItem,MDBNavLink,MDBContainer,MDBCol,MDBRow} from 'mdbreact';
import {Carousel,CarouselInner,CarouselItem,View,Mask} from 'mdbreact';
import {Button,Jumbotron} from 'mdbreact';
import {MDBBadge,MDBIcon} from 'mdbreact';
import ReactWeather from 'react-open-weather';
import Scroll from './Scroll';
import Maps from './Maps';
import Maps1 from './Maps1';
import FacilitiesPage from './FacilitiesPage';
import ActivityPage from './ActivityPage';
import './MainPage2.css';
import NBar from '../../components/Home/Small/NBar';
import Gallery from './Gallery';
import Weatherme2 from '../../components/Weatherme2';
import Weatherme3 from '../../components/Weatherme3';
import Weatherchotu from '../../components/Weatherchotu';
import { Link, animateScroll as scroll } from "react-scroll";
import Nearby from './Nearby';
import Reviews from './Reviews';
import Review from './Review';
import Reviewnew from './Reviewnew';
import Reviewnaya from './Reviewnaya';
import ReviewPra from './ReviewPra';


import GalScroll from './GalScroll';
import ReactDOM from 'react-dom';

class MainPage2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
        }
    }
    componentDidMount() {
      const login_details = sessionStorage.getItem('login');
      if(login_details){
            this.setState({ data: [] });
            fetch('https://emanjhaapi.com/locapi/add_track/'+this.props.match.params.id1+'/'+sessionStorage.getItem('user_id'), { });
        }
        else{
            fetch('https://emanjhaapi.com/locapi/add_track/'+this.props.match.params.id1+'/0/', { });
        }

      fetch(`https://emanjhaapi.com/locapi/get_location/${this.props.match.params.id1}`)
          .then(res => res.json())
          .then(json => {
              this.setState({
                  isLoaded: true,
                  items: json
              })
          });
    }

    render() {
         //let id1=this.props.match.params.id1;
         const pathare= 'California';
         const pathare1=' California';
         const yadav='California';
         const istri= '6';
         const rajdhani='6';
         const rajdhani1='21.33'
         const rajdhani2='21.223'
         const rajdhani3='31.34';
         const haarke='6';
         var { isLoaded, items } = this.state;
          if (!isLoaded) {
            return <div>Loading...</div>;
          }
      return(
        <div className="lightgrey">              
          <Router>              
            <NBar/>
          </Router>
          <Carousel
            activeItem={1}
            length={3}
            showControls={true}
            showIndicators={false}
            className="z-depth-1">
          <CarouselInner>
            <CarouselItem itemId="1">
              <View>               
                <img className="d-block w-100 image" style={{height:"40vw"}} src={items.location.location_images.img1} alt="First slide" />
                <Mask overlay="none" style={{flexDirection: 'column', height: '100vh'}} className=" flex:center text-white text-center">
                      <h1 className="text-white head99">{items.location.location_name}</h1>                      
                </Mask> 
                <div className="bottomrt HideOnMob"><Weatherme2 pathare={items.location.location_city}/></div>
                <div className="bottom ViewOnMob"><Weatherchotu pathare1={items.location.location_city}/></div>             
              </View>
            </CarouselItem>
            <CarouselItem itemId="2">
              <View>
                <img className="d-block w-100 image" style={{height:"40vw"}} src={items.location.location_images.img2} alt="Second slide" />
                <Mask overlay="none" style={{flexDirection: 'column', height: '100vh'}} className=" flex:center text-white text-center">
                      <h1 className="text-white  text-center head99">{items.location.location_name}</h1>                      
                </Mask> 
                <div className="bottomrt HideOnMob"><Weatherme2 pathare={items.location.location_city}/></div>
                <div className="bottom ViewOnMob"><Weatherchotu pathare1={items.location.location_city}/></div>
              </View>      
            </CarouselItem>
            <CarouselItem itemId="3">
              <View>
                <img className="d-block w-100 image" style={{height:"40vw"}} src={items.location.location_images.img3} alt="Third slide" />
                <Mask overlay="none" style={{flexDirection: 'column', height: '100vh'}} className=" flex:center text-white text-center">
                      <h1 className="text-white  text-center head99">{items.location.location_name}</h1>                      
                </Mask> 
                <div className="bottomrt HideOnMob"><Weatherme2 pathare={items.location.location_city}/></div>
                <div className="bottom ViewOnMob"><Weatherchotu pathare1={items.location.location_city}/></div>
              </View>              
            </CarouselItem>
          </CarouselInner>
        </Carousel>

             
                
            <div className="sticky-top bar2 text-center"> 
              <Link activeClass="active" to="test1" spy={true} smooth={true} offset={-120} duration= {500}>
                <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Info</Button>
              </Link>
              <Link activeClass="active" to="test2" spy={true} smooth={true} offset={-120} duration= {500}>
                <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Activities</Button>
              </Link>
              <Link activeClass="active" to="test3" spy={true} smooth={true} offset={-120} duration= {500}>
                <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Facilities</Button>
              </Link>
              <Link activeClass="active" to="test4" spy={true} smooth={true} offset={-120} duration= {500}>
                <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Weather</Button>
              </Link>
              <Link activeClass="active" to="test5" spy={true} smooth={true} offset={-120} duration= {500}>
               <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Gallery</Button>
              </Link>
              <Link activeClass="active" to="test6" spy={true} smooth={true} offset={-120} duration= {500}>
               <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Map</Button>
              </Link>  
              <Link activeClass="active" to="test7" spy={true} smooth={true} offset={-120} duration= {500}>
               <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Nearby</Button>
              </Link>        
              <Link activeClass="active" to="test8" spy={true} smooth={true} offset={-120} duration= {500}>
               <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Review</Button>
              </Link>        
            </div>  
         <MDBContainer fluid>
           <MDBRow>
             <MDBCol md="9">
              <div>

              <div id="test1" className="container-fluid cont1">      
                <p className="font-weight-bold">BASIC PARK INFORMATION</p>
                <p>{items.location.location_overview}</p> 
                <b> <p>Click <a href="https://www.themeparkbrochures.net/wp-content/create-zip.php?vs=MjA1Ng=="><u> here </u></a>to download park brochure</p></b>
              </div>

               <div id="test2">
                  <div className="container-fluid cont1">
                    <p className="font-weight-bold">Activities</p>                                
                    <br/>
                    <ActivityPage vati={items.location.location_id}/>
                  </div>
                </div>

                <div id="test3">
                  <div className="container-fluid cont1 ">
                    <p className="font-weight-bold">Facilities</p>  
                    <FacilitiesPage vati={items.location.location_id}/>
                    <br/>             
                  </div>
                </div>    
           
                <div id="test4" className="container-fluid cont12" >
                    <p className="font-weight-bold">Weather</p>                                
                    <Weatherme3 yadav={items.location.location_city}/>               
                </div>

                <div id="test5" className="container-fluid cont12" >
                    <p className="font-weight-bold">Gallery</p>                                
                    <br/>
                    <GalScroll istri={items.location.location_id}/>           
                </div> 

                <div id="test6" className="container-fluid cont12">
                    <p className="font-weight-bold">Maps</p>                                
                     <p>Nearest gas station is in ___ miles</p>
                     <p><b>Address</b>:&nbsp;  {items.location.location_address}</p>
                    <div><Maps rajdhani1={items.location.location_latitude} rajdhani2={items.location.location_longitude} rajdhani3={items.location.location_name}/>   </div>   



                </div> 

                 <div id="test7" className="container-fluid cont12" >
                    <p className="font-weight-bold">Nearby Places to {items.location.location_name }: </p>                                
                    <br/>
                    <Nearby haarke={items.location.location_id}/>           
                </div>
                {/* <div id="test8" className="container-fluid cont12" >
                    <p className="font-weight-bold">Reviews</p>                                
                    <Review ankh={items.location.location_id}/>          
                </div>*/}
                 <div id="test8" className="container-fluid cont12" >
                    <p className="font-weight-bold">Reviewnew</p>                                
                    <ReviewPra ankh={items.location.location_id}/> 
                </div>
          </div>
          </MDBCol>
          <MDBCol md="3" className="cont12"><p>space for advertisements</p>
          </MDBCol>
          </MDBRow>
          </MDBContainer>
           
      </div>
        );
    }
}
export default MainPage2;  