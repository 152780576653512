import React, { Component } from 'react';
import './ActivityHome.css'
class Month extends Component {
    render() {
        return (
            <div className="title">
           
                <center>

                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/j.png"/></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/f.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/m.png" /></a>
                <a href="#" className="hvr-grow"> <img className="rounded-circle m-3" alt="" src="assets/months/a.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/m.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/j.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/j.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/a.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/s.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/o.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/n.png" /></a>
                <a href="#" className="hvr-grow"><img className="rounded-circle m-3" alt="" src="assets/months/d.png" /></a>

</center>


            </div>
           
        );
    }
}

export default Month;