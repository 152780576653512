import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Route, Redirect } from 'react-router'
class formank extends React.Component{
    constructor() {

        super();
        this.state = {
            data: [{
                success: ''
            }]
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        fetch('http://159.89.175.187/login/', {
          method: 'POST',
          body: data,
        })
        .then(response => response.json())
        .then(data => this.setState({data}));
    }



    render() {
     
        return (
            <div>
              <form onSubmit={this.handleSubmit}>
                <label htmlFor="user_email">Enter Email</label>
                <input id="user_email" name="user_email" type="text" />
                <br />
                <label htmlFor="user_password">Enter Password</label>
                <input id="user_password" name="user_password" type="password" />
                <br />
                <button>Login Now!</button>
              </form>

              <br />
              <div>
                  Response is : {this.state.data[0].success}
              </div> 

                     
            </div>
        );
    }
}

export default formank; 
