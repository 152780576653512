import React, { Component } from 'react';
import NBar from "../NBar";
import { MDBMedia } from 'mdbreact';
import {Card,CardImage,CardBody,CardTitle,CardText,Fa,Button,CardGroup} from 'mdbreact';

import { Container, Row, Col, Input } from 'mdbreact';
import './Surfing.css'

class Surfing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        items: [],
        isLoaded: false
        //id2:this.props.match.params.id1
    }
}
componentDidMount() {
  //const { match :{ params } } = this.props;
  //let id2=this.props.match.params.id1;
 // const {n15} =this.props.match.params.n15;
    fetch(`http://159.89.175.187/locapi/get_loc_activity/5`)
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaded: true,
                items: json
            })
        });
}

      render() {
       
        //  let n15=this.props.match.params.n15;
          var { isLoaded, items } = this.state;
              if (!isLoaded) {
                  return <div>Loading...</div>;
              }
           return (
            <div  >
           <NBar/>
           
         <br/><br/>

           <Row className="onemore1">
        {items.map(item=> (

      <Col className="mt-4 needmoney1">
                <Card className="mx-auto" style={{ width: "16rem", height:"13rem" }}>
                  <CardImage className="img-fluid" style={{height:"10rem", width:"100%"}}src={item.location_images.fimg} waves/>
                  <CardBody  className="elegant-color white-text">
                    <CardTitle><a className="white-text" href={`/${item.location_country}/${item.location_state}/${item.location_id}`}>{item.location_name}</a></CardTitle>
                  </CardBody>
                </Card><br/><br/>
              </Col> ))}</Row>
        
           
        
            </div>
        );
    }
}

export default Surfing;  