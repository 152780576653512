import React, { Component } from 'react';
import { Container, Fa,Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';

class Alert extends Component {
  state = {
    modal: false
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    return (
      <Container>
        {/* BUTTON */}
        <Fa icon=" fa-television" onClick={this.toggle} />
        
        {/* MODAL */}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Alert title</ModalHeader>
          <ModalBody>
            SC orders Sabarimala temple to be openend for women 
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
            
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
export default Alert;
