import React from 'react';
import './Weatherme2.css'
class Weatherme2 extends React.Component{
	 constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
	 componentDidMount() {
    	
        fetch(`https://api.apixu.com/v1/current.json?key=8dbe3361b0e54bcc808191248190903&q=${this.props.pathare}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
	
	render(){
		var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
		<div>
			<div className="container-fluid conta mt-5 d-flex flex-row">
            {/* <img src={items.current.condition.icon}/> */}
               <span className="hastar">{items.current.temp_c}&deg;C</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<ul className="hi">
                    <li><i>{items.current.condition.text}</i></li>
                    <li className="chotu">Wind:&nbsp;{items.current.wind_mph}mph</li>
                    <li className="chotu">Humidity:&nbsp;{items.current.humidity}</li>
                 </ul>
                {/*Pressure: {items.main.pressure}</p>

					humidity: {items.main.humidity} Temp: min:{items.main.temp_min} max:{items.main.temp_max}
                     {items.weather[0].icon}
                <img src={`http://openweathermap.org/img/w/${items.weather[0].icon}.png`}/>*/}    
			</div>
        </div>

			)
	}
}

export default Weatherme2;