import React from 'react';
import {geolocated} from 'react-geolocated';
 import NearYou from './NearYou';
class Geo extends React.Component {
  render() {
    const sd='72.3';
    const ds='14.2';
    return !this.props.isGeolocationAvailable
      ? <div>Your browser does not support Geolocation</div>
      : !this.props.isGeolocationEnabled
        ? <div><NearYou sd="72.3" ds="14.2"/></div>
        : this.props.coords
          ? <table>
            <tbody>
            {/*
              <tr><td>latitude</td><td>{this.props.coords.latitude}</td></tr>
              <tr><td>longitude</td><td>{this.props.coords.longitude}</td></tr> */}
              <NearYou sd={this.props.coords.latitude} ds={this.props.coords.longitude}/>          
            </tbody>
          </table>
          
          : <div>Getting the location data&hellip; </div>;
  }
}
 
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Geo);