import React,{Component} from 'react';
import {MDBContainer,MDBCol,MDBRow} from 'mdbreact';
import './Reviews.css'

class Reviews extends Component{
	render(){
		return(
			<div>


	<MDBContainer>
      <MDBRow>
        <MDBCol md="2"><img src="https://mdbootstrap.com/img/Photos/Avatars/img(31).jpg" style={{height:'80px'}} className="img-fluid z-depth-1 rounded-circle" alt="" /><p>SHreya CHavan</p></MDBCol>
        <MDBCol md="10"><span className="motha"><b className="sda">Ok Ok beach</b></span><span className="rate sda">
    <input type="radio" id="star5" name="rate" value="5" />
    <label for="star5" title="text">5 stars</label>
    <input type="radio" id="star4" name="rate" value="4" />
    <label for="star4" title="text">4 stars</label>
    <input type="radio" id="star3" name="rate" value="3" />
    <label for="star3" title="text">3 stars</label>
    <input type="radio" id="star2" name="rate" value="2" />
    <label for="star2" title="text">2 stars</label>
    <input type="radio" id="star1" name="rate" value="1" />
    <label for="star1" title="text">1 star</label>
  </span><br/><br/><p>At Alibaug beach,two immediate things to notice as one sees the beach are : beach sports and the Kolaba fort on the right side. There are few beach sports here in Alibaug beach like horse riding, 4-wheel bike rides. We saw the Kolaba fort.</p></MDBCol>
      </MDBRow>
      <hr/>
<MDBRow>
        <MDBCol md="2"><img src="https://mdbootstrap.com/img/Photos/Avatars/img(31).jpg" style={{height:'80px'}} className="img-fluid z-depth-1 rounded-circle" alt="" /><p>Sundnda Avadh</p></MDBCol>
        <MDBCol md="10"><span className="motha"><b className="sda">Thike beach</b></span><span className="rate sda">
    <input type="radio" id="star5" name="rate" value="5" />
    <label for="star5" title="text">5 stars</label>
    <input type="radio" id="star4" name="rate" value="4" />
    <label for="star4" title="text">4 stars</label>
    <input type="radio" id="star3" name="rate" value="3" />
    <label for="star3" title="text">3 stars</label>
    <input type="radio" id="star2" name="rate" value="2" />
    <label for="star2" title="text">2 stars</label>
    <input type="radio" id="star1" name="rate" value="1" />
    <label for="star1" title="text">1 star</label>
  </span><br/><br/><p>libag beach is crowded, noisy n not as clean as other beaches..places to visit nearby are colaba fort, korlai fort, janjira fort, kashid beach, kihim beach, nagaon beach, revdanda beach, chaul beach, birla temple..u must travel from ferry/ maldar jetty from mandva beach to gateway</p></MDBCol>
      </MDBRow>
<hr/>
<MDBRow>
        <MDBCol md="2"><img src="https://mdbootstrap.com/img/Photos/Avatars/img(31).jpg" style={{height:'80px'}} className="img-fluid z-depth-1 rounded-circle" alt="" /><p>Sundnda Avadh</p></MDBCol>
        <MDBCol md="10"><span className="motha"><b className="sda">ok beach</b></span><span className="rate sda">
    <input type="radio" id="star5" name="rate" value="5" />
    <label for="star5" title="text">5 stars</label>
    <input type="radio" id="star4" name="rate" value="4" />
    <label for="star4" title="text">4 stars</label>
    <input type="radio" id="star3" name="rate" value="3" />
    <label for="star3" title="text">3 stars</label>
    <input type="radio" id="star2" name="rate" value="2" />
    <label for="star2" title="text">2 stars</label>
    <input type="radio" id="star1" name="rate" value="1" />
    <label for="star1" title="text">1 star</label>
  </span><br/><br/><p>alibag beach is crowded, noisy n not as clean as other beaches..places to visit nearby are colaba fort, korlai fort, janjira fort, kashid beach, kihim beach, nagaon beach, revdanda beach, chaul beach, birla temple..u must travel from ferry/ maldar jetty from mandva beach to gateway</p></MDBCol>
      </MDBRow>



      

    </MDBContainer>
    <div className="heathen" md="10">

    <h4>Write your own reviews here</h4>
    <div>
	<p className="sda"><b>Your overall ratings of this attraction:</b></p><span className="rate">
    <input type="radio" id="star5" name="rate" value="5" />
    <label for="star5" title="text">5 stars</label>
    <input type="radio" id="star4" name="rate" value="4" />
    <label for="star4" title="text">4 stars</label>
    <input type="radio" id="star3" name="rate" value="3" />
    <label for="star3" title="text">3 stars</label>
    <input type="radio" id="star2" name="rate" value="2" />
    <label for="star2" title="text">2 stars</label>
    <input type="radio" id="star1" name="rate" value="1" />
    <label for="star1" title="text">1 star</label>
  </span>	
  </div>
  <br/><br/>
  <div>
		<p ><b>Title of your review:</b></p>	<div className="form-group">
           
            <textarea
            className="form-control missng"
            id="exampleFormControlTextarea1"
            rows="2"

            />
        </div>	
   </div>	
		<p><b>Your Review:</b></p>		<div className="form-group">
            
            <textarea
            className="form-control missng"
            id="exampleFormControlTextarea1"
            rows="2"

            />
        </div>		
				
				
</div>







			</div>
			);
	}
}

export default  Reviews 
