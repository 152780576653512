import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown, Modal, Button, Form, FormControl, Row } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import '../../../components/MainPage/godfather.css';
import Modaly from './../Modaly';
import Logout from './Logout';
import './NBar.css';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon,MDBFormInline} from "mdbreact";

class NBar extends React.Component{
	constructor(props) {
	    super(props);
	    this.state = {
	    	comp: '',
	    	login: false,
	    	show: false,
	    	data : [],
	    	keyword: '',
	    	loading: 'Loading Results'
	    }

	    this.handleShow = this.handleShow.bind(this);
    	this.handleClose = this.handleClose.bind(this);
    	this.setKeyword = this.setKeyword.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	}


	setKeyword(event){
		this.setState({ keyword: event.target.value });
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow(event) {
		//const data = new FormData(event.target[0].value);
		//alert('data is  ' +this.state.keyword)
		this.setState({loading: 'Loading Results'});
		this.setState({ data: [] });
		fetch('https://emanjhaapi.com/locapi/search/name/'+this.state.keyword, {

		})
		.then(response => response.json())
		.then(data => this.setState({data}));
		this.setState({loading: ''});
		this.setState({ show: true });
	}

	handleSubmit(event){
		event.preventDefault();
		this.handleShow();
	}


	componentDidMount() {
	    const login_details = sessionStorage.getItem('login');

	    if(login_details){
	    	const logged_user = (
	    		 	<NavDropdown title={ sessionStorage.getItem('user_name') } id="collasible-nav-dropdown">
				        
				        <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
				      </NavDropdown>
	    		);
	    	this.setState({login: true})
	    	this.setState({comp: logged_user})
	    }
	    else{
	    	this.setState({login: false})
	    	this.setState({comp: <Nav.Link href="/login">Login/Register</Nav.Link>})
	    }
	 }


	render() {
		var locs = this.state.data;
	    return (
		    <div>
				<Navbar collapseOnSelect className="" expand="lg" bg="dark" style={{position: "relative"}}variant="dark">
				  <Navbar.Brand href="/"><b>Travel As U Go</b></Navbar.Brand>
				  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
				  <Navbar.Collapse id="responsive-navbar-nav" bg="dark">
				    <Nav className="mr-auto">
				      <Nav.Link href="/" style={{color:"white" }}>Home</Nav.Link>
				      <Nav.Link href="/states" style={{color:"white" }}>States</Nav.Link>
				      <Nav.Item className="pqr"><Modaly/></Nav.Item>
				      <NavDropdown title="Locations" id="collasible-nav-dropdown">
				        <NavDropdown.Item href="/states">All states</NavDropdown.Item>
				        <NavDropdown.Item href="/abcdus/b">Abcd page</NavDropdown.Item>
				        <NavDropdown.Item href="/Allact">activities</NavDropdown.Item>
				      </NavDropdown>
				    </Nav>
				    <Form onSubmit={this.handleSubmit} className="hds" inline>
				      <FormControl type="text" name="keyword" placeholder="Search" className="hds mr-sm-2 mt-1 mb-1" size="sm" onChange={this.setKeyword} />
				      <Button className="hds" variant="outline-success" size="sm" onClick={this.handleShow}>Search</Button>
					  <Button className="hds" variant="outline-success" size="sm" href="/SearchDet">Detailed</Button>

				    </Form>
				    <Nav className="hds">
				      {this.state.comp}
				    </Nav>
				  </Navbar.Collapse>
				</Navbar>


				

			<Modal 
			show={this.state.show} 
			onHide={this.handleClose}
			style={{width: '90%'}}

			>
	          <Modal.Header closeButton>
	            <Modal.Title>Search Results </Modal.Title>
	          </Modal.Header>
	          <Modal.Body>

				<sm>{this.state.loading}</sm>
	          
			    { 
			      	locs.map(function(loc){
			      		const lnk = "/"+loc.location_country+"/"+loc.location_state+"/"+loc.location_id+"/";
                        return (
                        	<div style={{ margin: '2px'}} className="border border-light" > 
                        		<a href={lnk}><p className="liss ml-4 mt-1 black-text">{ loc.location_name }</p></a>
                        	</div>
                        	);
                    })
                }


	          </Modal.Body>
	          {/*<Modal.Footer>
	            <Button variant="secondary" size="sm" onClick={this.handleClose}>
	              Close
	            </Button>
	          </Modal.Footer>
	      */}
	        </Modal>

	        </div>

	    );
	}

}

export default NBar;