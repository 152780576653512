import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Button, Form, Container, Row, Col, Card, Jumbotron, Image, FormControl } from 'react-bootstrap';

class Search extends React.Component{

	constructor() {
	    super();
	    this.state = {
	    	data : []
	    }
	    this.handleSubmit = this.handleSubmit.bind(this);
	    
	}

	componentDidMount(){    
		fetch('https://emanjhaapi.com/locapi/alllocations/', {
		  method: 'POST'
		})
		.then(response => response.json())
		.then(data => this.setState({data}));
	}

	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);

		fetch('http://159.89.175.187/locapi/search_post/', {
		  method: 'POST',
		  
		  body: data,
		})
		.then(response => response.json())
		.then(data => this.setState({data}));
	}

	render() {
		var locs = this.state.data;
	    return (
		    <div>
				<center> <h2> Browse Locations </h2></center>
				<hr />
				<form onSubmit={this.handleSubmit} inline>
					    <FormControl type="text" placeholder="Enter search term" className="mr-sm-2" size="sm" name="keyword" required/>
					    
					    <center>
					        Search BY : &nbsp; &nbsp; &nbsp; &nbsp;
							      <Form.Check inline label="Name" type='radio' value="name" name="param" checked/>
							      <Form.Check inline label="City" type='radio' value="city" name="param"  />
							      <Form.Check inline label="State" type='radio' value="state" name="param"  />
							      <Form.Check inline label="Address" type='radio' value="address" name="param"  />
							  
					    </center>

					    <center>
						    <Button variant="primary" size="sm" type="submit">
						    Search
						    </Button>
					    </center>
			      </form>





	              

	               <div id="locations" >
			      	<div style={{padding: '5px'}}>
					  <h6>Locations</h6>
					</div>
					<hr />
				     <Row>
				      { 
				      	locs.map(function(loc){
				      		const lnk = "http://159.89.175.187/locapi/get_location/"+loc.location_id+"/";
	                        return (
	                        
	                        <Col sm={6} >
			                    <Row className="border border-light" style={{'padding': '2px', margin:'2px'}}>
								    <Col sm={2} >
								    	<Image style={{'max-height': '96px', 'width':'100%'}} src={ loc.location_images } fluid ></Image>
								    </Col>

								    <Col sm={10}>

								    	<Row>
								    		<Col sm={6}>
								    			<p style={{"font-size": '0.7rem'}}>
								    			<b><a href={lnk}>{ loc.location_name }</a></b>
								    			<br />
								    			City : { loc.location_city }
								    			<br />
								    			Country : { loc.location_country }
								    			</p>
								    		</Col>
								    		<Col sm={6}>
								    			<p style={{"font-size": '0.7rem'}}>
								    			&nbsp;
								    			<br />
								    			State : { loc.location_state }
								    			<br />
								    			Zipcode : { loc.location_zip }
								    			</p>
								    		</Col>
								    	</Row>
								    	
								    </Col>
								</Row>	
							</Col>	
						);
	                      })
	                  }
	                 </Row>
	              </div>



		    </div>
	    );
	}

}

export default Search; 