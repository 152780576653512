import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";
import {Button} from 'mdbreact';

class MainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
        };
    this.onClick = this.onClick.bind(this);
    }

    onClick(){
        this.setState({
            collapse: !this.state.collapse,
        });
    }
    render() {
        return (
            
         <div>        
            <Router>              
                <Navbar color="blue" dark expand="md" scrolling>
                    <NavbarBrand href="/">
                        <strong>Emanjha.net</strong>
                    </NavbarBrand>
                    { !this.state.isWideEnough && <NavbarToggler onClick = { this.onClick } />}
                    <Collapse isOpen = { this.state.collapse } navbar>
                        <NavbarNav left>
                          <NavItem active>
                              <a href="#babu1">Home</a>
                          </NavItem>
                          <NavItem>
                              <NavLink to="#babu2">Places</NavLink>
                          </NavItem>
                          <NavItem>
                              <a href="#babu3">Location</a>
                          </NavItem>
                          <NavItem>
                              <a href="#babu4">Sign in</a>
                          </NavItem>
                          
                          
                        </NavbarNav>
                        <NavbarNav right>
                          <NavItem>
                            <form className="form-inline md-form mt-0">
                                <input className="form-control mr-sm-2 mb-0 text-white " type="text" placeholder="Search" aria-label="Search"/>
                            </form>
                          </NavItem>
                        </NavbarNav>
                    </Collapse>
                </Navbar>
            </Router>

            <div className="sticky-top bar2 text-center"> 
                   
                       <Link activeClass="active" to="babu3" spy={true} smooth={true} offset={-70} duration= {500}>
                         <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">hi</Button>
                        </Link>
                         <Link activeClass="active" to="babu1" spy={true} smooth={true} offset={-70} duration= {500}>
                         <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Activities</Button>
                        </Link>
                         <Link activeClass="active" to="babu2" spy={true} smooth={true} offset={-70} duration= {500}>
                         <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Facilities</Button>
                        </Link>
                         <Link activeClass="active" to="babu4" spy={true} smooth={true} offset={-70} duration= {500}>
                         <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Weather</Button>
                        </Link>
                         <Link activeClass="active" to="babu6" spy={true} smooth={true} offset={-70} duration= {500}>
                         <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Gallery</Button>
                        </Link>
                         <Link activeClass="active" to="babu6" spy={true} smooth={true} offset={-70} duration= {500}>
                         <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd">Gallery</Button>
                        </Link>
                 
                
              </div>
            <Link
    activeClass="active"
    to="babu3"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}
>hi</Link>
             <div id="babu1">
             <h1>section 1</h1>
             <img src="assets/fishing.png" height="500px"/><p>lol</p>
             </div>
             <div id="babu2">
             <h1>section 2</h1>
             <img src="assets/boating.png" height="500px"/><p>lol</p>
             </div>
             <div id="babu3">
             <h1>section 3</h1>
             <img src="assets/picinic.png" height="500px"/><p>lol</p>
             </div>
             <div id="babu4">
             <h1>section 4</h1>
             <img src="assets/map.png" height="500px"/><p>lol</p>
             </div>


            <br/><br/><br/>
           
            </div>
        );
    }
}

export default MainPage;  