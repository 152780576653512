import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {Navbar, NavbarBrand, NavbarToggler,Collapse,NavbarNav,NavItem,MDBNavLink, MDBContainer} from 'mdbreact';
import {Carousel,CarouselInner,CarouselItem,View,Mask} from 'mdbreact';
import {Tabs,Tab} from 'react-bootstrap';
import {Button,AnchorLink,Jumbotron} from 'mdbreact';
import ReactWeather from 'react-open-weather';
import Scroll from './Scroll';
import Maps from './Maps';
import FacilitiesPage from './FacilitiesPage';
import ActivityPage from './ActivityPage';
import './MainPage2.css';
import NBar from '../../components/NBar';
import Gallery from './Gallery';
import Weatherme2 from '../../components/Weatherme2';
import Weatherme3 from '../../components/Weatherme3';
import GalScroll from './GalScroll';

class MainPage3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }

    componentDidMount() {
      //const {id1} =this.props.match.params.id1;
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
        fetch(`https://emanjhaapi.com/locapi/get_location/${this.props.match.params.id1}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }

    render() {
         //let id1=this.props.match.params.id1;
         const pathare= 'California';
         const yadav='California';
         const istri= '6';
        var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return (
            <div >
              
               <Router>              
                <NBar/>
            </Router>

            <Carousel
          activeItem={1}
          length={3}
          showControls={true}
          showIndicators={false}
          className="z-depth-1">
          <CarouselInner>
            <CarouselItem itemId="1">
              <div className="cortry pt-3" style={{backgroundImage:`url(${items.location.location_images.img0})`}}>
                <h1 className="text-white text-center head2 ">{items.location.location_name}</h1>
                <div className="bottomrt"><Weatherme2 pathare={items.location.location_city}/></div>
              </div>
            </CarouselItem>
            <CarouselItem itemId="2">
              <div className="cortry pt-3" style={{backgroundImage:`url(${items.location.location_images.img1})`}}>
                <h1 className="text-white text-center head2">{items.location.location_name }</h1>
                <div className="bottomrt"><Weatherme2 pathare={items.location.location_city}/></div>
              </div>
            </CarouselItem>
            <CarouselItem itemId="3">
              <div className="cortry pt-3" style={{backgroundImage:`url(${items.location.location_images.img2})`}}>
                <h1 className="text-white text-center head2">{items.location.location_name }</h1>
                <div className="bottomrt"><Weatherme2 pathare={items.location.location_city}/></div>
              </div>
            </CarouselItem>
             
           {/*<CarouselItem itemId="2">
              <View>
                <img className="d-block w-100 image " src={items[0].images.img2} alt="Second slide" />
                <Mask overlay="black-light" style={{flexDirection: 'column', height: '100vh'}} className="align-center  text-white text-center">
                  <h1 className="h1 h1-resposive heading d-none d-xl-none d-lg-block d-xl-block">{ items[0].name }</h1>
                  <div className= "float-right HideOnMob"> 
                    <ReactWeather
                        forecast="today"  
                        apikey="e094159f9b9142b8bb3120824180612"
                        type="geo"
                        lat={items[0].latitude}
                        lon={items[0].longitude}/>
                   </div>
                </Mask>
              </View>      
            </CarouselItem>
            <CarouselItem itemId="3">
              <View>
                <img className="d-block w-100 image" src={items[0].images.img3} alt="Third slide" />
                <Mask overlay="black-light" style={{flexDirection: 'column', height: '100vh'}} className="align-center  text-white text-center">
                  <h1 className="h1 h1-resposive heading d-none d-xl-none d-lg-block d-xl-block">{ items[0].name }</h1>
                  <div className= "float-right HideOnMob" > 
                    <ReactWeather
                      forecast="today"  
                      apikey="e094159f9b9142b8bb3120824180612"
                      type="geo"
                       lat={items[0].latitude}
                       lon={items[0].longitude}/>
                  </div>
                </Mask>
              </View>              
            </CarouselItem>
            <CarouselItem itemId="4">
              <View>
                <img className="d-block w-100 image" src={items[0].images.img4} alt="Mattonit's item" />
                <Mask overlay="black-light" style={{flexDirection: 'column', height: '100vh'}} className="align-center  text-white text-center">
                  <h1 className="h1 h1-resposive  heading d-none d-xl-none d-lg-block d-xl-block">{ items[0].name }</h1>
                  <div className= "float-right HideOnMob" > 
                    <ReactWeather
                        forecast="today"    
                        apikey="e094159f9b9142b8bb3120824180612"
                        type="geo"
                        lat={items[0].latitude}
                        lon={items[0].longitude}/>
                  </div>
                </Mask>
              </View>             
            </CarouselItem>*/}
          </CarouselInner>
        </Carousel>
        <center>
          <h3 className="ViewOnMob "> {items.location.location_name }</h3>
        </center>

        <div className="sticky-top bar2 text-center"> 
            <Button className="list-unstyled SmallOnMob but2" color="#e3f2fd"> 
                Info</Button>
            <Button className=" list-unstyled SmallOnMob but2"  color="#e3f2fd">
                Activities</Button>
            <Button className=" list-unstyled SmallOnMob but2"  color="#e3f2fd">
                Facilities</Button>
            <Button className="list-unstyled SmallOnMob but2"  color="#e3f2fd">
                Site Map</Button>
            <Button className="list-unstyled SmallOnMob but2"  color="#e3f2fd">
                Gallery</Button>
            <Button className="list-unstyled SmallOnMob but2"  color="#e3f2fd">
                reaching</Button>
            <Button className="list-unstyled SmallOnMob but2"  color="#e3f2fd">
                Weather</Button>
        </div>
<Tabs className="tab2" defaultActiveKey={1} id="uncontrolled-tab-example">
  <Tab eventKey={1} title="Info">
        <div id="test1" className="container-fluid cont1">
            <p className="font-weight-bold">VISITING  {items.location.location_name}</p>
            <p class="text-justify">This beach is a popular location for exploring tide pools and surf fishing. The beach is bounded by low hills both to the north and south. Restaurants and grocery stores are nearby. Point Montara has a lighthouse that offers overnight accommodations. It is operated by Hostelling International, a non-profit group. 

             Montara mountain (also known as McNee Ranch) is part of Montara State Beach. The mountain is a northern spur of the Santa Cruz Mountains and features the only undisturbed Coastal Mountain Habitat found over 100 miles of coastline.
             
            </p>
        </div>


  <div id="test1" className="container-fluid cont1">      
          <p className="font-weight-bold">BASIC PARK INFORMATION</p>
          <p>{items.location.location_overview}</p>
     </div>
       </Tab>
     

      <Tab eventKey={2} title="Activities">
            <div id="test2" className="container-fluid cont1">
              <p className="font-weight-bold">ACTIVITIES</p>
              <ActivityPage/>
            </div>
         </Tab>

           <Tab eventKey={3} title="Facilities">                
             <div id="test3" className="container-fluid cont1">
                <p className="font-weight-bold">FACILITIES/AMENITIES</p>            
                <FacilitiesPage/>
              </div>
        </Tab>

      <Tab eventKey={4} title="map">
            <div id="test4" className="container-fluid cont1" >
                <p className="font-weight-bold">SITE MAP</p>                                
                <br/>
                <center><img src="assets/map.png" className="img-fluid"></img></center>
            </div>
              
</Tab>
<Tab eventKey={5} title="Weather">
        
             <div id="test4" className="container-fluid cont12" >
                <p className="font-weight-bold">Weather</p>                                
                <br/>
                <Weatherme3 yadav={items.location.location_city}/>               
            </div>
</Tab>
<Tab eventKey={6} title="Activities">
             <div id="test4" className="container-fluid cont12" >
                <p className="font-weight-bold">Gallery</p>                                
                <br/>
                <GalScroll istri={items.location.location_id}/>               
            </div>

</Tab>
</Tabs>

 
  


            
              
              

      

      
               
            












{/*

              <hr />
              City: <h1>{ items[0].city } </h1>
               <h1> Location Name :{id1} { items[0].name } </h1>
              <br />
              State: { items[0].state }
              <br />
              Country: { items[0].country }
              <br />
              Zipcode: { items[0].zipcode }
              <hr />
              
              <img src="{ items[0].images.0.image_url } " alt="Smiley face" height="42" width="42" /> 
*/}
            </div>
        );
    }
}
export default MainPage3;  