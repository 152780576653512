import React, { Component } from "react";
import ReactDOM from "react-dom";
import './Gallery.css'
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import './godfather.css';

class Gallery1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }

    componentDidMount() {
    	
    	//const { match :{ params } } = this.props;
    	//let id2=this.props.match.params.id1;
        fetch(`https://emanjhaapi.com/locapi/get_location/12`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }


 render() {
         
       	
        var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return (
            <div>
 				<Lightbox
  images={[
    { src: 'http://www.emanjha.com/emanjha/images/5c4f15c7bc357.jpg' },
    { src: 'http://www.emanjha.com/emanjha/images/5c4f15c7bbd11.jpg' }
  ]}
  isOpen={this.state.lightboxIsOpen}
  onClickPrev={this.gotoPrevLightboxImage}
  onClickNext={this.gotoNextLightboxImage}
  onClose={this.closeLightbox}
/>

			</div>
        );
    }
}
export default Gallery1;	