import React from "react";
import {MDBBtn, MDBBtnGroup} from "mdbreact";
import {Card,CardImage,CardBody,CardTitle,CardText,Fa,Button,CardGroup} from 'mdbreact';
import { Container, Row, Col, Input } from 'mdbreact';
import NBar from './Home/Small/NBar';
import {BrowserRouter as Router,Route,Link} from "react-router-dom";
import './abcd99.css';
import FooterPagePro from './FooterPagePro';

class Abcd extends React.Component{
  constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
      const {n1} =this.props.match.params.n1;
      const {n2} =this.props.match.params.n2;
      const {n3} =this.props.match.params.n3;

      
        fetch(`https://emanjhaapi.com/locapi/alphabet_s/${this.props.match.params.n3}/${this.props.match.params.n2}/${this.props.match.params.n1}`)
            .then(res => res.json())
            .then(json => {
                this.setState({  
                    isLoaded: true,
                    items: json
                })
            });
    }


  render(){
    
    let n1=this.props.match.params.n1;
    var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
    return(
      <div>
         
               <Router>              
                <NBar/>
               </Router>

     
          <div className="d-flex justify-content-center">

          
         <h4><b>Find places in {this.props.match.params.n2}</b></h4> 
         </div>
           
          <center><MDBBtn href="http://emanjha123.herokuapp.com/states" size="sm"  color="grey">click here to explore other states</MDBBtn></center>

         
         
       
         <br/>
         
        
      
<div className="container-fluid  HideOnMob stayaway">
      <MDBBtnGroup className="d-flex justify-content-center flex-wrap ">
        
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/a`} size="sm" color="grey" className="butaplha">a</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/b`} size="sm" color="grey" className="primary">b</MDBBtn>        
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/c`} size="sm" color="grey" className="butaplha">c</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/d`} size="sm" color="grey" className="butaplha">d</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/e`} size="sm" color="grey" className="butaplha">e</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/f`} size="sm" color="grey" className="butaplha">f</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/g`} size="sm" color="grey" className="butaplha">g</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/h`} size="sm" color="grey" className="butaplha">h</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/i`} size="sm" color="grey" className="butaplha">i</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/j`} size="sm" color="grey" className="butaplha">j</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/k`} size="sm" color="grey" className="butaplha">k</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/l`} size="sm" color="grey" className="butaplha">l</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/m`} size="sm" color="grey" className="butaplha">m</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/n`} size="sm" color="grey" className="butaplha">n</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/o`} size="sm" color="grey" className="butaplha">o</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/p`} size="sm" color="grey" className="butaplha">p</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/q`} size="sm" color="grey" className="butaplha">q</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/r`} size="sm" color="grey" className="butaplha">r</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/s`} size="sm" color="grey" className="butaplha">s</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/t`} size="sm" color="grey" className="butaplha">t</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/u`} size="sm" color="grey" className="butaplha">u</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/v`} size="sm" color="grey" className="butaplha">v</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/w`} size="sm" color="grey" className="butaplha">w</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/x`} size="sm" color="grey" className="butaplha">x</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/y`} size="sm" color="grey" className="butaplha">y</MDBBtn>
          <MDBBtn href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/z`} size="sm" color="grey" className="butaplha">z</MDBBtn>
        </MDBBtnGroup>
    </div>

    <div className="container-fluid ShowOnMob">
<ul className="mumbai pagination justify-content-center">
    <li className="zombie border border-dark rounded-left "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/a`}>a</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/b`}>b</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/c`}>c</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/d`}>d</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/e`}>e</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/f`}>f</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/g`}>g</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/h`}>h</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/i`}>i</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/j`}>j</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/k`}>k</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/l`}>l</a></li>
    <li className="zombie border border-dark rounded-right"><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/m`}>m</a></li>
    
   </ul>
   <ul className="mumbai pagination justify-content-center">
     <li className="zombie border border-dark rounded-left"><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/n`}>n</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/o`}>o</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/p`}>p</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/q`}>q</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/r`}>r</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/s`}>s</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/t`}>t</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/u`}>u</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/v`}>v</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/w`}>w</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/x`}>x</a></li>
    <li className="zombie border border-dark "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/y`}>y</a></li>
    <li className="zombie border border-dark rounded-right "><a className="white-text" href={`/Abcd/${this.props.match.params.n3}/${this.props.match.params.n2}/z`}>z</a></li>
  </ul>
</div>
        <br/>




        
            

      <Row className="onemore" style={{ display:"inline-block",width:"100%", height:"auto" }}>
        {items.map(item=> (

      <Col  className="mt-4 needmoney HideOnMob" >
                <Card className="mx-auto" style={{ width: "16rem", height:"13rem" }}>
                  <CardImage className="img-fluid " style={{height:"10rem", width:"100%" }}src={item.location_images.fimg} waves/>
                  <CardBody  className="elegant-color white-text " >
                    <CardTitle><a className="white-text" href={`/${item.location_country}/${item.location_state}/${item.location_id}`}><p className="mahi">{item.location_name}</p></a></CardTitle>
                  </CardBody>
                </Card><br/><br/>
              </Col> ))}</Row>
      


        <div className="ShowOnMob">
      
        {items.map(item => (

          <div className="ajnabe mt-1 ml-2 mr-2 p-1">
          <img className="scars" style={{width:"4rem",height:"4rem"}} src={item.location_images.fimg}/>
          <a className="black-text" href={`/${item.location_country}/${item.location_state}/${item.location_id}`}><p className="scars1 ">{item.location_name}</p></a>
          </div>


            ))}
      
      </div>      




<FooterPagePro/>

      </div>
      );
  }
}

export default Abcd