import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {Navbar, NavbarBrand, NavbarToggler,Collapse,NavbarNav,NavItem,MDBNavLink,MDBContainer,MDBCol,MDBRow} from 'mdbreact';
import {Carousel,CarouselInner,CarouselItem,View,Mask,MDBBtn,MDBCollapse} from 'mdbreact';
import {Button,Jumbotron} from 'mdbreact';
import {MDBBadge,MDBIcon} from 'mdbreact';
import Reviews from './Reviews';
import InfiniteCarousel from 'react-leaf-carousel';

import './MainPage2.css';
import './MainPage4.css';
import NBar from '../../components/NBar';
import Weatherme2 from '../../components/Weatherme2';
import Weatherme3 from '../../components/Weatherme3';
import Weatherchotu from '../../components/Weatherchotu';
import { Link, animateScroll as scroll } from "react-scroll";
import Nearby from './Nearby';
import ActivityPage from './ActivityPage';
import './MainPage5.css';

import ReactDOM from 'react-dom';
class MainPage5 extends Component {
    state = {
  collapseID: ""
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));
}
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
      
      
        fetch(`https://emanjhaapi.com/locapi/nearby_locations/12`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
    render() {
       	//let id1=this.props.match.params.id1;
         var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
         
         
        return (
            <div>       

             
            
             <div className="">
            <div className=" kolbi">
            
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[1].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[1].name} ({items.nearby_locs[1].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[2].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[2].name} ({items.nearby_locs[2].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[3].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[3].name} ({items.nearby_locs[3].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[4].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[4].name} ({items.nearby_locs[4].distance_ml} mi)</span></b></a>
            </div>
         </div>
        </div>
        <a color="primary" onClick={this.toggleCollapse("basicCollapse")} style={{ marginBottom: "1rem" }}>
          Show more
        </a>
        
        <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
           <div className="">
            <div className="kolbi">
            
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[1].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[1].name} ({items.nearby_locs[1].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[2].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[2].name} ({items.nearby_locs[2].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[3].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[3].name} ({items.nearby_locs[3].distance_ml} mi)</span></b></a>
            </div>
            <div className="ajnabehomie mt-1  p-1">
              <img className="scarshome" style={{width:"2.8rem",height:"2.8rem"}} src={items.nearby_locs[4].img.fimg}/>
              <a className="black-text" href="#"><b><span className="scars1home">{items.nearby_locs[4].name} ({items.nearby_locs[4].distance_ml} mi)</span></b></a>
            </div>
         </div>
        </div>
        </MDBCollapse>
      
      <MDBContainer fluid>
        <MDBRow>
        <MDBCol md="9">
        <div className="border">hello</div>
       </MDBCol>
       <MDBCol md="3">
        <div className="border">hi</div>
        </MDBCol>
        </MDBRow>
</MDBContainer>





      </div>
        );
    }
}
export default MainPage5;	
