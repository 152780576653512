import React from 'react';
import {MDBContainer,MDBRow,MDBCol} from 'mdbreact';
import './Weatherme3.css'
class Weatherme3 extends React.Component{
	 constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
	 componentDidMount() {
    	
        fetch(`http://api.apixu.com/v1/forecast.json?key=8dbe3361b0e54bcc808191248190903&q=${this.props.yadav}&days=7`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
	
	render(){
		var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
		<div>
             <MDBContainer  className="container-fluid contar1 ">
			
                
                    <MDBRow className="contar2">

                         <img className="ml-2" src={items.current.condition.icon}/>
                           <span className="hastar">{items.current.temp_c}&deg;C</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ul className="hi">
                            <li><i>{items.current.condition.text}</i></li>
                            <li className="chotu">Wind:&nbsp;{items.current.wind_mph}mph</li>
                            <li className="chotu">Humidity:&nbsp;{items.current.humidity}</li>
                         </ul>
                     </MDBRow>
                      <MDBRow className="contar3">
                        <MDBCol className="acg"><img src={items.forecast.forecastday[2].day.condition.icon}/>{items.forecast.forecastday[2].date} <p>{items.forecast.forecastday[2].day.condition.text}</p></MDBCol>
                        <MDBCol className="acg"><img src={items.forecast.forecastday[3].day.condition.icon}/> {items.forecast.forecastday[3].date}<p>{items.forecast.forecastday[3].day.condition.text}</p></MDBCol>
                        <MDBCol className="acg"><img src={items.forecast.forecastday[4].day.condition.icon}/> {items.forecast.forecastday[4].date}<p>{items.forecast.forecastday[4].day.condition.text}</p></MDBCol>
                        <MDBCol className="acg"><img src={items.forecast.forecastday[5].day.condition.icon}/> {items.forecast.forecastday[5].date}<p>{items.forecast.forecastday[5].day.condition.text}</p></MDBCol>
                      </MDBRow>
                      <MDBRow></MDBRow>
               </MDBContainer>

                {/*Pressure: {items.main.pressure}</p>

					humidity: {items.main.humidity} Temp: min:{items.main.temp_min} max:{items.main.temp_max}
                     {items.weather[0].icon}
                <img src={`http://openweathermap.org/img/w/${items.weather[0].icon}.png`}/>*/}    
			</div>

			)
	}
}

export default Weatherme3;