import React from "react";
import {MDBBtn, MDBBtnGroup} from "mdbreact";
import {Card,CardImage,CardBody,CardTitle,CardText,Fa,Button,CardGroup} from 'mdbreact';
import { Container, Row, Col, Input } from 'mdbreact';
import NBar from '../components/Home/Small/NBar';
import {BrowserRouter as Router,Route,Link} from "react-router-dom";
import './abcd99.css';
import FooterPagePro from './FooterPagePro';

class abcdin extends React.Component{
	constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
    	//const { match :{ params } } = this.props;
    	//let id2=this.props.match.params.id1;
    	const {n3} =this.props.match.params.n3;
    	
        fetch(`https://emanjhaapi.com/locapi/alphabet_c/India/${this.props.match.params.n3}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }


	render(){
		
		let n3=this.props.match.params.n3;
		var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
			<div className="samzha">
				 
               <Router>              
                <NBar/>
           		</Router>

           <div className="d-flex justify-content-center">

          
         <h2>Find places in India</h2> 
         </div>
         	
          <center><MDBBtn href="/abcdin/a" size="sm"  color="grey">Explore USA</MDBBtn></center>

         
         
       
         <br/>
        
			
<div className="container-fluid  HideOnMob stayaway">
			<MDBBtnGroup className="d-flex justify-content-center flex-wrap ">
			    <MDBBtn href="/abcdin/a" size="sm" color="grey " classname="butaplha">a</MDBBtn>
			    <MDBBtn href="/abcdin/b" size="sm" color="grey" className="butaplha">b</MDBBtn>		    
			    <MDBBtn href="/abcdin/c" size="sm" color="grey" className="butaplha">c</MDBBtn>
			    <MDBBtn href="/abcdin/d" size="sm" color="grey" className="butaplha">d</MDBBtn>
			    <MDBBtn href="/abcdin/e" size="sm" color="grey" className="butaplha">e</MDBBtn>
			    <MDBBtn href="/abcdin/f" size="sm" color="grey" className="butaplha">f</MDBBtn>
			    <MDBBtn href="/abcdin/g" size="sm" color="grey" className="butaplha">g</MDBBtn>
			    <MDBBtn href="/abcdin/h" size="sm" color="grey" className="butaplha">h</MDBBtn>
			    <MDBBtn href="/abcdin/i" size="sm" color="grey" className="butaplha">i</MDBBtn>
			    <MDBBtn href="/abcdin/j" size="sm" color="grey" className="butaplha">j</MDBBtn>
			    <MDBBtn href="/abcdin/k" size="sm" color="grey" className="butaplha">k</MDBBtn>
			    <MDBBtn href="/abcdin/l" size="sm" color="grey" className="butaplha">l</MDBBtn>
			    <MDBBtn href="/abcdin/m" size="sm" color="grey" className="butaplha">m</MDBBtn>
			    <MDBBtn href="/abcdin/n" size="sm" color="grey" className="butaplha">n</MDBBtn>
			    <MDBBtn href="/abcdin/o" size="sm" color="grey" className="butaplha">o</MDBBtn>
			    <MDBBtn href="/abcdin/p" size="sm" color="grey" className="butaplha">p</MDBBtn>
			    <MDBBtn href="/abcdin/q" size="sm" color="grey" className="butaplha">q</MDBBtn>
			    <MDBBtn href="/abcdin/r" size="sm" color="grey" className="butaplha">r</MDBBtn>
			    <MDBBtn href="/abcdin/s" size="sm" color="grey" className="butaplha">s</MDBBtn>
			    <MDBBtn href="/abcdin/t" size="sm" color="grey" className="butaplha">t</MDBBtn>
			    <MDBBtn href="/abcdin/u" size="sm" color="grey" className="butaplha">u</MDBBtn>
			    <MDBBtn href="/abcdin/v" size="sm" color="grey" className="butaplha">v</MDBBtn>
			    <MDBBtn href="/abcdin/w" size="sm" color="grey" className="butaplha">w</MDBBtn>
			    <MDBBtn href="/abcdin/x" size="sm" color="grey" className="butaplha">x</MDBBtn>
			    <MDBBtn href="/abcdin/y" size="sm" color="grey" className="butaplha">y</MDBBtn>
			    <MDBBtn href="/abcdin/z" size="sm" color="grey" className="butaplha">z</MDBBtn>
  			</MDBBtnGroup>
  	</div>

  	<div className="container-fluid ShowOnMob">
<ul className="mumbai pagination justify-content-center">
    <li className="zombie border border-dark rounded-left"><a className="white-text" href="/abcdin/a">a</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/b">b</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/c">c</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/d">d</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/e">e</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/f">f</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/g">g</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/h">h</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/i">i</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/j">j</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/k">k</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/l">l</a></li>
    <li className="zombie border border-dark rounded-right"><a className="white-text" href="/abcdin/m">m</a></li>
    
   </ul>
   <ul className="mumbai pagination justify-content-center">
   	<li className="zombie border border-dark rounded-left "><a className="white-text" href="/abcdin/n">n</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/o">o</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/p">p</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/q">q</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/r">r</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/s">s</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/t">t</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/u">u</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/v">v</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/w">w</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/x">x</a></li>
    <li className="zombie border border-dark "><a className="white-text" href="/abcdin/y">y</a></li>
    <li className="zombie border border-dark rounded-right"><a className="white-text" href="/abcdin/z">z</a></li>
  </ul>
</div>




  
  			<br/>
  			<Row className="onemore" style={{ display:"inline-block",width:"100%", height:"auto" }}>
  			{items.map(item=> (

			<Col key={item.location_id} className="mt-4 needmoney HideOnMob">
                <Card className="mx-auto" style={{ width: "16rem", height:"13rem" }}>
                  <CardImage className="img-fluid" style={{height:"10rem", width:"100%"}}src={item.location_images.fimg} waves/>
                  <CardBody  className="elegant-color white-text" >
                    <CardTitle><a className="white-text" href={`/India/${item.location_state}/${item.location_id}`}><p className="mahi">{item.location_name}</p></a></CardTitle>
                  </CardBody>
                </Card><br/><br/>
              </Col> ))}</Row>
			


  			<div className="ShowOnMob">
			
				{items.map(item => (

					<div  key={item.location_id} className="ajnabe mt-1 ml-2 mr-2 p-1">
					<img style={{width:"4rem",height:"4rem"}} src={item.location_images.fimg}/>
					&nbsp;<a className="black-text" href={`/India/${item.location_state}/${item.location_id}`}>{item.location_name}</a>
					</div>


						))}
			
			</div>
      <FooterPagePro/>
			
 
			

			</div>




			);
	}
}

export default abcdin