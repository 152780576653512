import React from 'react';
import {MDBContainer,MDBRow,MDBCol,toastr} from 'mdbreact';
import styled from 'styled-components';
import ee from 'event-emitter';

const Container = styled.div`
background-color: #444;
color:white;
padding: 16px;
position: absolute;
top: ${props =>props.top}px;
right: 16px;
z-index: 999;
transition: top 0.5s ease;

> i {
    margin-left:8px;
}

`;

const emitter = new ee();

export const notify = (msg) => {
    emitter.emit('notification', msg);
}

class Alert1 extends React.Component{
     constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
            top: -700,
            msg: '',
        };
        this.timeout = null;
        emitter.on('notification',(msg)=>{
           this.onshow(msg);
        });
    }
   
    onshow =(msg) => {
     if(this.timeout){
         clearTimeout(this.timeout);
         this.setState({top: -700}, () => {
             this.timeout = setTimeout(()=>{
                 this.showNotification(msg);

             },500);
         });
     } else {
         this.showNotification(msg);
     }
    }

     showNotification =(msg) => {
         this.setState({
             top: -100,
             msg
         },()=>{
      this.timeout = setTimeout(()=>{
                  this.setState({
                   top:-700,
                  });
              },3000); 
         });
     }


	 componentDidMount() {
    	
        fetch(`https://developer.nps.gov/api/v1/alerts?&api_key=o2W3ZuxbhAVR5u5HfU9wPISaaC5GhVdmHL9XhD2i`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
    
	render(){
		var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <div>Loading...</div>;
     }
       
    return(
		<div>
             <div id="folder-1">Wind:&nbsp;{items.data["1"].title}</div>
                        <React.Fragment> 
                        {/* <button onClick={()=>notify('this is a notification')}>Clic me</button>      */}
                       <Container top={this.state.top}>{items.data["1"].title}<i className="fa fa-bell"></i></Container>
                       </React.Fragment> 
               </div>  

			)
	}
}

export default Alert1;