import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import ReactTimeout from 'react-timeout';
import Navbar from '../../components/NBar';

class Reviewnaya extends React.Component{
    constructor() {
        super();
        this.state = {
            data: {
                flag: 0,
                button_text: "",
                obj: []
            },
            loc_id: 5,
            user_id: 0,
            button_id: 0,
            success: "Not Yet",
            button_text: "Submit"
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        const login_details = sessionStorage.getItem('login');

        if(login_details){
            this.setState({user_id: sessionStorage.getItem('user_id')})
            fetch('https://emanjhaapi.com/locapi/get_comments_user/'+this.props.ankh+'/'+this.state.user_id, {
              method: 'GET'
            })
            .then(response => response.json())
            .then(data => this.setState({data}));
        }
        else{
            this.setState({user_id: 0});
            this.setState({button_id: this.state.data.flag}); 
            this.setState({button_text: "Please Login to add your Review"});
            fetch('https://emanjhaapi.com/locapi/get_comments/'+this.props.ankh, {
              method: 'GET'
            })
            .then(response => response.json())
            .then(data => this.setState({data}));           
        }




    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        fetch('https://emanjhaapi.com/locapi/add_comment/', {
          method: 'POST',
          body: data,
        })
        .then(response => response.json())
        .then((responseJson) => {
            fetch('https://emanjhaapi.com/locapi/get_comments/'+this.props.ankh, {
              method: 'GET'
            })
            .then(response => response.json())
            .then(data => this.setState({data}));
        });
    }


    render() {
        return (
            <Container fluid="true">
                                <Navbar/>  
            
            <Row>
                <Col xs={12} lg={6}>          
                  <form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Rate the Location</Form.Label>
                        <Form.Control as="select" name="comment_stars" disabled={this.state.data.flag}>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>

                        </Form.Control>
                      </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Enter Review</Form.Label>
                        <Form.Control as="textarea" rows="3" name="comment_text" disabled={this.state.data.flag}/>
                     </Form.Group>

                     <input type="hidden" value={this.props.ankh} name="loc_id" />
                     <input type="hidden" value={this.state.user_id} name="user_id" />

                      <Button variant="success" type="submit" id="submit_button" disabled={this.state.data.flag}>
                        {this.state.data.button_text}
                      </Button>
                  </form>


                <hr />
                <h3>Reviews</h3>
                    {
                        this.state.data.obj.map(function(comment){
                            return(
                            <div>
                                <label>{comment.user_name} </label>
                                <br />
                                <label> Rating: {comment.comment.comment_stars} </label>
                                <br />
                                <label> Review: {comment.comment.comment_text} </label>
                                <hr />
                            </div>
                            );
                        })
                    }

                  </Col>
                  <Col>
                         

                  </Col>



             </Row>

            </Container>
        );
    }
}

export default Reviewnaya; 

