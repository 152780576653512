import ReactDOM from 'react-dom';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn } from "mdbreact";
import React, { Component } from 'react';
import { Container, Row, Col, Input,Card,CardImage,CardBody, CardTitle } from 'mdbreact';

import './ActivityHome.css'

class NearYou extends Component {
   constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
      
        fetch(`https://emanjhaapi.com/locapi/get_nearby/${this.props.ds}/${this.props.sd}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
    render() {
      var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return (
<div className="title">
  <div className="HideOnMob">
             <Row>
                {this.state.items.nearby_locs.slice(0,7).map(item=> (            
                <center><Col>
                  <div style={{width:"10rem"}}>
                    <img  className="img-fluid" alt='' src={item.img.fimg} waves style={{height:"9vw"}}/>
                    <h4 className="quick"><a href={`/${item.country}/${item.state}/${item.id}`}>{item.name} &nbsp; ({item.distance_ml} miles)</a></h4>             
                  </div>
                </Col></center>
                ))}
              </Row>
            
         
         </div>

         <div className="ShowOnMob kolbi">
             {this.state.items.nearby_locs.slice(0,7).map(item=> (
            <div className="ajnabehome mt-1 ml-1 mr-1 p-1">
              <img className="scarshome" style={{width:"2.2rem",height:"2.2rem"}} src={item.img.fimg}/>
              <a className="black-text" href={`/${item.country}/${item.state}/${item.id}`}><b><span className="scars1home">{item.name}</span></b></a>
            </div>
))}
           
         </div>

       </div>
        );
    }
}

export default NearYou;

