
import ReactDOM from 'react-dom';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import React, { Component } from 'react';
import './GalScroll.css';

class GalScroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
            //id2:this.props.match.params.id1
        }
    }
   
    componentDidMount() {
      //const {id1} =this.props.match.params.id1;
      //const { match :{ params } } = this.props;
      //let id2=this.props.match.params.id1;
        fetch(`https://emanjhaapi.com/locapi/get_location/${this.props.istri}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
    toggle = () => {
        this.setState({
          modal: !this.state.modal
        });
      }

   render() {
     var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return (
          <div>
<div className="title" >
  
          
            <InfiniteCarousel
            breakpoints={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  showSides: false,
                },
              },
              {
                breakpoint: 100,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  showSides: false,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  showSides: false,
                },
              },
            ]}
            
            dots={false}
            showSides={false}
            sidesOpacity={1}
            sideSize={.1}
            slidesToScroll={2}
            slidesToShow={3}
            scrollOnDevice={false}
            responsive={true}
          >

            <div>
   
            </div>
            <div>
              <img className="img-fluid wohu" alt='sorry! image unavailable' src={items.location.location_images.img3} onerror="this.src='https://i.ibb.co/p37tXhc/Screenshot-2019-05-21-sorry-image-not-available-Google-Search.png';" waves />
            </div>
            <div>
              <img className="img-fluid wohu" alt='sorry! image unavailable' src={items.location.location_images.img2} waves />
            </div>
            <div>
              <img className="img-fluid wohu" alt='sorry! image unavailable' src={items.location.location_images.img1} waves />
            </div>
           
            <div>
              <img className="img-fluid wohu" alt='sorry! image unavailable' src={items.location.location_images.img4} waves />
            </div>
            <div>
              <img className="img-fluid wohu" alt='sorry! image unavailable' src={items.location.location_images.img1} waves />
            </div>
            
          </InfiniteCarousel>

          </div>




          </div>
        );
    }
}

export default GalScroll;


 