import React, { Component } from 'react';

class Logout extends React.Component{
	componentDidMount(){
		sessionStorage.removeItem('login');
		sessionStorage.removeItem('user_id');
		sessionStorage.removeItem('user_name');
		this.props.history.push('/');
	}

	render(){
		return (
			<div></div>
			);
	}
}
export default Logout;  