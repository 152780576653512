import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter,MDBIcon } from "mdbreact";

const FooterPage = () => {
  return (
    <MDBFooter color="elegant-color" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol size="9">
            <h5 className="title">Contact details</h5>
            <p>Fremount California</p>
            <p>Phone No</p>
           
          </MDBCol>
          <p align ="right"> <a href ="https://www.facebook.com/shreya.chavan.102977"><MDBIcon  fab icon="facebook-square" size="lg" /></a>&nbsp;&nbsp;
          <a href ="https://www.instagram.com/emanjha_/"> <MDBIcon fab icon="instagram" size="lg" /></a>&nbsp;&nbsp;
          <a href ="https://twitter.com/Shreya48697035?lang=en"> <MDBIcon fab icon="twitter-square" size="lg" /></a> </p>
          <MDBCol size ="4">
           
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="http://emanjha123.herokuapp.com/home1"> Emanjha.net </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;