import React, { Component } from 'react';
import { Container, Row, Col, Input } from 'mdbreact';
import {Card,CardImage,CardBody,CardTitle,CardText,Fa} from 'mdbreact';
import {Navbar,NavItem,NavLink,NavbarBrand,Dropdown,DropdownToggle,NavbarToggler,Collapse,NavbarNav,DropdownMenu,DropdownItem,FormInline} from 'mdbreact';
import {Button} from 'mdbreact';
import {Modal,Header} from 'react-bootstrap';
import ReactWeather from 'react-open-weather';
import Modaly from './Modaly';
import Video from './video';
import Month from './month';
import FooterPage from './Footer';
import Geo from './Geo';
import './Home1.css';
import {Form ,FormControl} from 'react-bootstrap';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
  MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon,MDBCol } from "mdbreact";

import NBar from '../../components/Home/Small/NBar';
import ActivityHome from './ActivityHome';
import FacilityHome from './FacilityHome';
import NearYou from './NearYou';
import PlacesList from './PlacesList';
import FooterPagePro from '../../components/FooterPagePro'
import 'react-open-weather/lib/css/ReactWeather.css';
import { BrowserRouter as Router } from 'react-router-dom';
class Home1 extends Component {
 constructor(props) {
      super(props);
      this.state = {
        comp: '',
        login: false,
        show: false,
        data : [],
        keyword: '',
        loading: 'Loading Reasults'
      }

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.setKeyword = this.setKeyword.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

  }


  setKeyword(event){
    this.setState({ keyword: event.target.value });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(event) {
    //const data = new FormData(event.target[0].value);
    //alert('data is  ' +this.state.keyword)
    this.setState({loading: 'Loading Results'});
    this.setState({ data: [] });
    fetch('https://emanjhaapi.com/locapi/search/name/'+this.state.keyword, {

    })
    .then(response => response.json())
    .then(data => this.setState({data}));
    this.setState({loading: ''});
    this.setState({ show: true });
  }

  handleSubmit(event){
    event.preventDefault();
    this.handleShow();
  }



   
    render() {
    var locs = this.state.data;

        return (
          <div>
         <div>
          <header>

         


          <Router>
     <NBar/>
    </Router>








         {/* <Router>
              <Navbar dark expand="md">
                <NavbarBrand >
                    <strong>Hikers</strong>
                </NavbarBrand>
                <NavbarToggler onClick = { this.onClick } />
                <Collapse isOpen = { this.state.collapse } navbar>
                  <NavbarNav left>
                   
                  <NavItem  className="modaly">
            <Dropdown>
              <DropdownToggle nav caret>
                <div className="d-none d-md-inline">India</div>
              </DropdownToggle>
              <DropdownMenu className="dropdown-default" right>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdMah/a">Maharashtra</DropdownItem>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdPun/a">Punjab</DropdownItem>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdKeral/a">Keral</DropdownItem>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdGoa/a">Goa</DropdownItem>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdGuj/a">Gujarat</DropdownItem>
    
              </DropdownMenu>
            </Dropdown>
          </NavItem>

          <NavItem  className="modaly">
            <Dropdown>
              <DropdownToggle nav caret>
                <div className="d-none d-md-inline">US</div>
              </DropdownToggle>
              <DropdownMenu className="dropdown-default" right>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdCali/a">California</DropdownItem>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdUtah/a">Utah</DropdownItem>
                <DropdownItem href="https://emanjha123.herokuapp.com/AbcdIdaho/a">Idaho</DropdownItem>
               
              </DropdownMenu>
            </Dropdown>
          </NavItem>
                    <NavItem className="modaly2" style={{ marginTop: "7.5px" }} >
                         
                       <Modaly/>
                      
                    </NavItem>
                    
                  </NavbarNav>
                </Collapse>
              </Navbar>
            </Router>
 */}
            </header>
            </div>
        

<div class="video-header wrap kardi HideOnMob">
  <div class="fullscreen-video-wrap">
    <video src="assets/bgvid.mp4" autoPlay loop muted style={{width:'200vx'}}></video>
  </div>
  <div class="header-overlay mb-5 ">
    <div class="header-content">
      <h1 id="head1">Travel As U Go</h1>
      <p>Yesterday was past, tomrw is future and today is a gift. Thats why it is called a present</p>
      <center>
        <Col>
           <Form onSubmit={this.handleSubmit} inline>
           
    <FormControl type="text" name="keyword" placeholder="Search" style={{width:'600px'}} className="shum1" onChange={this.setKeyword} />
              <Button color="black" className="shum3" size="md" onClick={this.handleShow}>Search</Button>
              <Button color="black" className="shum4" size="md" href="/SearchDet">Detailed search</Button>
              
            </Form>
        </Col>
      </center>
      
    </div>
  </div>
</div>
<Modal 
      show={this.state.show} 
      onHide={this.handleClose}
      style={{width: '90%'}}

      >
            <Modal.Header closeButton>
              <Modal.Title>Search Results  </Modal.Title>
            </Modal.Header>
            <Modal.Body>

        <sm>{this.state.loading}</sm>
            
          { 
              locs.map(function(loc){
                const lnk = "/"+loc.location_country+"/"+loc.location_state+"/"+loc.location_id+"/";
                        return (
                          <div style={{ margin: '2px'}} className="border border-light" > 
                            <a href={lnk}><p className="liss ml-4 mt-1">{loc.location_name }</p></a>
                          </div>
                          );
                    })
                }


            </Modal.Body>
            {/*<Modal.Footer>
              <Button variant="secondary" size="sm" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer> */}
          </Modal>



<div className="container ShowOnMob p-0 m-0">
<video src="assets/bgvid.mp4" autoPlay loop muted style={{ width:'100%' }}></video>
  
  <div class="centered"><h1 className="text-white head99" style={{fontSize:'19px'}}>Travel As U Go</h1></div>
</div>


    <div className="container-fluid">
    
      <div className="container-fluid cont12" >
                    <p className="font-weight-bold">Activities</p>                                
                    <br/>
                    <ActivityHome/>           
      </div> 
      <div className="container-fluid cont12" >
                    <p className="font-weight-bold">Facility</p>                                
                    <br/>
                    <FacilityHome/>           
             </div> 

       <div className="container-fluid cont12" >
                    <p className="font-weight-bold">NearYou</p>                                
                    <br/>
                    <p><Geo/></p>
             </div>

        <div className="container-fluid cont12" >
                    <p className="font-weight-bold">By Places</p>                                
                    <br/>
                    <p><PlacesList/></p>
             </div> 
        
        
         <FooterPagePro/>
        
    </div>

</div>
    
              );
    }
}

export default Home1;