import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { MDBBtn ,MDBInput} from "mdbreact";
import './Login.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
 



class Register extends React.Component{
	constructor() {
	    super();
	    this.state = {
	    	data: [{
	    		success: ''
	    	}]
	    }
	    this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);

		fetch('https://emanjhaapi.com/emanjhaapi/register/', {
		  method: 'POST',
		  body: data,
		})
		.then(response => response.json())
		.then(data => this.setState({data}));
	}


	render() {
	    return (
            <div>
            <h3>Register</h3>    
            <form onSubmit={this.handleSubmit}>
            <div className="form-group">
            <label htmlFor="formGroupExampleInput">First Name</label>
            <input
            className="form-control" autocomplete="off"
              id="formGroupExampleInput" type="text" placeholder="First Name" size="sm" name="user_fname"  required
            />
            <label htmlFor="formGroupExampleInput">Last Name</label>
            <input
            className="form-control" autocomplete="off"
              id="formGroupExampleInput1" type="text" placeholder="Last Name" size="sm" name="user_lname"  required
            />
             <label htmlFor="formGroupExampleInput">City</label>
            <input
            className="form-control"
              id="formGroupExampleInput2" type="text" placeholder="City" size="sm" name="user_city" autocomplete="off" required
            />
             <label htmlFor="formGroupExampleInput">State</label>
            <input
            className="form-control"
              id="formGroupExampleInput3" type="text" placeholder="State" size="sm" name="user_state" autocomplete="off" required
            />
             <label htmlFor="formGroupExampleInput">Country</label>
             <select  className="form-control" type ="select"
              id="formGroupExampleInput4"  size="sm" name="user_country" required>
    <option value="USA">USA</option>
    <option value="INDIA">INDIA</option>
   
</select>
             <label htmlFor="formGroupExampleInput">Phone</label>
             <PhoneInput   className="form-control"
              id="formGroupExampleInput5" type="tel"  size="sm" name="user_phone" autocomplete="off" required
    placeholder="Enter phone number"
    value={ this.state.phone }
    onChange={ phone => this.setState({ phone }) } />


             <label htmlFor="formGroupExampleInput">Email</label>
            <input
            className="form-control"
              id="formGroupExampleInput6" type="email" placeholder="Enter email" size="sm" name="user_email" autocomplete="off" required
            />
             <label htmlFor="formGroupExampleInput">Password</label>
            <input
            className="form-control"
              id="formGroupExampleInput7" type="password" placeholder="Password" size="sm" name="user_password" autocomplete="off" required
            />

       
<br></br>
{/* <div className="container">
<label htmlFor="formGroupExampleInput">User Interests</label>
<div className="row">

<div className="col-sm-6">
<div className="custom-control custom-checkbox">

<label>
						<input type="checkbox" name="check"  /> <span class="label-text">Scubadiving</span>
					</label>
</div>
<div className="custom-control custom-checkbox">
<label>
						<input type="checkbox" name="check" /> <span class="label-text">Kiyaking</span>
					</label>
</div>
<div className="custom-control custom-checkbox">
<label>
						<input type="checkbox" name="check"  /> <span class="label-text">Boating</span>
					</label>
</div>
				<div className="custom-control custom-checkbox">
				<label>
						<input type="checkbox" name="check"  /> <span class="label-text">Hiking</span>
					</label>
				</div>
		    
      </div>
      <div className="col-sm-6">
<div className="custom-control custom-checkbox">
<label>
						<input type="checkbox" name="check"  /> <span class="label-text">Swimming</span>
					</label>
</div>
<div className="custom-control custom-checkbox">
<label>
						<input type="checkbox" name="check" /> <span class="label-text">Horse Riding</span>
					</label>
</div>
<div className="custom-control custom-checkbox">
<label>
						<input type="checkbox" name="check"  /> <span class="label-text">Wild Life</span>
					</label>
</div>
				<div className="custom-control custom-checkbox">
				<label>
						<input type="checkbox" name="check"  /> <span class="label-text">Fishing</span>
					</label>
				</div>
		    </div>
      </div>
		</div> */}

    <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" id="defaultUnchecked" feedback="You must agree before submitting" />
    <label class="custom-control-label" for="defaultUnchecked"> I Agree the Terms and Conditions</label>
</div>

         
            <MDBBtn color="primary" type="submit" >Register</MDBBtn>
          
        
		     
          </div>
          
          </form>
         
				</div>
			
	    );
	}
}

export default Register;  