import React from 'react';
import './weatherme.css'
class weatherme extends React.Component{
	 constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
	 componentDidMount() {
    	
        fetch(`http://api.openweathermap.org/data/2.5/weather?q=London,uk&APPID=26e92b7dea5d38afe2906722d1e1fe45`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }
	
	render(){
		var { isLoaded, items } = this.state;

        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
			<div>
				<div className="container-fluid conta mt-5">
				
				<p>Temp: {items.main.temp}  Pressure: {items.main.pressure}</p>

					humidity: {items.main.humidity} Temp: min:{items.main.temp_min} max:{items.main.temp_max}
                     {items.weather[0].icon}
                <img src={`http://openweathermap.org/img/w/${items.weather[0].icon}.png`}/>

				</div>
			</div>


			)
	}
}

export default weatherme;