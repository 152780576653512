import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import './Scroll.css';




  

class Scroll extends Component {
    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
      }
      openLightbox(event, obj) {
        this.setState({
          currentImage: obj.index,
          lightboxIsOpen: true,
        });
      }
      closeLightbox() {
        this.setState({
          currentImage: 0,
          lightboxIsOpen: false,
        });
      }
      gotoPrevious() {
        this.setState({
          currentImage: this.state.currentImage - 1,
        });
      }
      gotoNext() {
        this.setState({
          currentImage: this.state.currentImage + 1,
        });
      }
  render() {
    const photos = [
    { src: `${this.props.bang}`, width: 4, height: 3 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 1, height: 1 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 3, height: 4 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 3, height: 4 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 3, height: 4 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 4, height: 3 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 3, height: 4 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 4, height: 3 },
    { src: 'http://www.emanjha.com/emanjha/images/5c768b3289919.jpg', width: 4, height: 3 }
  ];
  
    return (
      <Scrollbars
        autohide
        style={{ height: 300, width: 1200, margin: "0 auto" }} className="hello"
      >
        
 
      <div>
        <Gallery photos={photos} onClick={this.openLightbox} />
        <Lightbox images={photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
          </div>
          {this.props.bang}
         
      </Scrollbars>
    );
  }
}
export default Scroll