
import ReactDOM from 'react-dom';
import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBContainer,MDBRow,MDBCol } from "mdbreact";
import {Row,Col,Container,MDBCollapse} from 'mdbreact';
import React, { Component } from 'react';
import './ActivityHome.css';

class PlacesList extends Component {
  constructor(props){
    super(props);
    this.state={
      items:[],
      isLoaded:false
    }
  }

componentDidMount(){
  fetch(`https://emanjhaapi.com/locapi/get_tags/`)
    .then(res=>res.json())
    .then(json=>{
      this.setState({
        isLoaded:true,
        items:json
      })
    });
}
  

    render() {
      var { isLoaded, items } = this.state;
              if (!isLoaded) {
                  return <div>Loading...</div>;
              }
        return (
<div className="title">
  
         <div className="HideOnMob ">
            <Container fluid>
              <Row>
                {items.map(item=> (            
                <center><Col>
                  <div>
                    <img  className="img-fluid" alt='' src={item.tag_img} waves style={{height:"9vw",width:"9rem"}}/>
                    <h4 className="quick"><a href={`/cat/United States/${item.tag_name}`}>{item.tag_name}</a></h4>             
                  </div>
                </Col></center>
                ))}
              </Row>
              <br/>
               </Container>
                </div>
            
                <div className="ShowOnMob kolbi">
          
          <MDBContainer className="m-0 p-0 koi ">
             <MDBRow className="mt-1">
                 {items.map(item=>( 
                   
                   <MDBCol size="5" className="mt-1 ml-1 mr-1 p-0"><img className="rounded-circle border border-dark p-1 mr-1 intern1" style={{height:"2rem",width:"2rem"}} src={item.tag_img}/><b><a className="black-text intern" href={`/cat/United States/${item.tag_name}`}>{item.tag_name}</a></b></MDBCol>
                
                   ))}
                  
                 </MDBRow>
                    
           </MDBContainer>        
  
          </div>
          
 

          
          </div>
        );
    }
}

export default PlacesList;


 