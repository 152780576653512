
import ReactDOM from 'react-dom';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn } from "mdbreact";
import React, { Component } from 'react';
import './ActivityHome.css'
class Video extends Component {
    render() {
        return (
<div className="title">
  <br/><br/>
           <center> <h3>Video Gallery</h3></center>
            <InfiniteCarousel
            breakpoints={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
            ]}
            dots={true}
            showSides={true}
            sidesOpacity={.5}
            sideSize={.1}
            slidesToScroll={4}
            slidesToShow={4}
            scrollOnDevice={true}
          >
            <div className="embed-responsive embed-responsive-4by3">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/vlDzYIIOYmM" allowfullscreen></iframe>
              
            </div>
            <div className="embed-responsive embed-responsive-4by3">
               <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/vlDzYIIOYmM" allowfullscreen></iframe>
            </div>
            <div className="embed-responsive embed-responsive-4by3">
               <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/vlDzYIIOYmM" allowfullscreen></iframe>
            </div>
            <div className="embed-responsive embed-responsive-4by3">
               <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/vlDzYIIOYmM" allowfullscreen></iframe>
            </div>
            <div>
              <img
                alt=''
                src='assets/m1.jpg'
              />
            </div>
            <div>
              <img
                alt=''
                src='assets/m2.jpg'
              />
            </div>
            <div>
              <img
                alt=''
                src='assets/m3.jpg'
              /> 
            </div>
            <div>
              <img
                alt=''
                src='assets/m4.jpg'
              />
            </div>
            <div>
              <img
                alt=''
                src='assets/m1.jpg'
              />
            </div>
            
          </InfiniteCarousel>
          </div>
        );
    }
}

export default Video;


 