
import ReactDOM from 'react-dom';
import { Container, Row, Col, Input,Card,CardImage,CardBody,CardTitle } from 'mdbreact';

import InfiniteCarousel from 'react-leaf-carousel';
import { MDBBtn,MDBContainer,MDBRow,MDBCol } from "mdbreact";
import React, { Component } from 'react';
import './ActivityHome.css';
import NBar from "../../components/Home/Small/NBar";
import FooterPagePro from '../../components/FooterPagePro';


class Allfac extends Component {

  constructor(props){
    super(props);
    this.state={
      items:[],
      isLoaded:false
    }
  }

componentDidMount(){
  fetch(`https://emanjhaapi.com/facilities/`)
    .then(res=>res.json())
    .then(json=>{
      this.setState({
        isLoaded:true,
        items:json
      })
    });
}
    render() {
      var { isLoaded, items } = this.state;
              if (!isLoaded) {
                  return <div>Hiiiiii</div>;
              }
              var ip = require('ip');
              ip.address()
        return (
  
<div className="title">

  <NBar/>
  <br/>
  <h2>All the Facilities</h2>
  <br/>

  <br/>

  
          <div className="HideOnMob">

          <Row className="onemore" style={{ display:"inline-block",width:"100%", height:"auto" }}>
        {items.map(item=> (

      <Col key={item.activity_id} className="mt-4 needmoney HideOnMob">
                <Card className="mx-auto" style={{ width: "16rem", height:"13rem" }}>
                  <CardImage className="img-fluid" style={{height:"11rem", width:"100%"}}src={item.facility_img} waves/>
                  <CardBody  className="elegant-color" >
                    <CardTitle><a className="white-text" href={`/facility/${item.facility_name}/${item.facility_id}`}><p className="mahi">{item.facility_name}</p></a></CardTitle>
                  </CardBody>
                </Card><br/><br/>
              </Col> ))}</Row>
             
            
            
          </div>
        

          <div className="ShowOnMob cont1">
           <MDBContainer className="m-0 p-0 koi ">
           <MDBRow className="mt-1">
               {items.map(item=>( 
                 
                 <MDBCol size="5" className="mt-1 ml-3 mr-2 p-0"><img className="rounded-circle border border-dark p-1 mr-1" style={{height:"2rem",width:"2rem"}} src={item.facility_img}/><b><a href={`/facility/${item.facility_name}/${item.facility_id}`}><span className="black-text">{item.facility_name}</span></a></b></MDBCol>
              
                 ))}
                
               </MDBRow>
                  
         </MDBContainer>
         </div>
         <FooterPagePro/>
          </div>
        );
    }
}

export default Allfac;


 