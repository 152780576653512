import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { BrowserRouter as Router,Route } from 'react-router-dom';

import Home from '../components/Home/Home';
import MainPage from '../components/MainPage/MainPage';
import MainPage1 from '../components/MainPage/MainPage1';
import Home1 from '../components/Home/Home1';
import Home2 from '../components/Home/Home2';
import Geo from '../components/Home/Geo';

import Abcd from '../components/Abcd';
import MainPage2 from '../components/MainPage/MainPage2';
import MainPage3 from '../components/MainPage/MainPage3';
import MainPage4 from '../components/MainPage/MainPage4';
import MainPage5 from '../components/MainPage/MainPage5';
import MainPage6 from '../components/MainPage/MainPage6';
import MainPage7 from '../components/MainPage/MainPage7';


import Review from '../components/MainPage/Review';
import Reviews from '../components/MainPage/Reviews';
import Reviewnew from '../components/MainPage/Reviewnew';
import Reviewnaya from '../components/MainPage/Reviewnaya';


import Search from '../components/Home/Search';


import Maps from '../components/MainPage/Maps';
import Maps1 from '../components/MainPage/Maps1';

import abcd99 from '../components/abcd99';
import formank from '../components/Home/formank';
import abcdin from '../components/abcdin';
import abcdus from '../components/abcdus';
import radiotest from '../components/radiotest';
import radiotest1 from '../components/radiotest1';
import Login from '../components/Home/Login';
import Register from '../components/Home/Register';
import weatherme from '../components/weatherme';
import Weatherme2 from '../components/Weatherme2';
import states from '../components/states';

import Gallery1 from '../components/MainPage/Gallery';
import Loginscreen from '../components/Home/Loginscreen';
import Surfing from "../components/Home/Surfing";
import FaraweRanch from "../components/Home/FaraweRanch";
import BeachVolleyball from "../components/Home/BeachVolleyball";
import Paragliding from "../components/Home/Paragliding";
import MuseumVisit from "../components/Home/MuseumVisit";
import Skiing from "../components/Home/Skiing";
import Swimming from "../components/Home/Swimming";
import Fishing from "../components/Home/Fishing";
import GoKarting from "../components/Home/GoKarting";
import Camping from "../components/Home/Camping";
import HorseRiding from "../components/Home/HorseRiding";
import Activity from '../components/Home/Activity';
import Allact from '../components/Home/Allact';
import Allfac from '../components/Home/Allfac';
import AllusNat from '../components/AllusNat';
import AllindNat from '../components/AllindNat';

import PlacesGoUS from '../components/Home/PlacesGoUS';
import PlacesGoInd from '../components/Home/PlacesGoInd';
import Contactus from '../components/Contactus';
import Logout from '../components/Home/Small/Logout';



import Facility from '../components/Home/Facility';


import SearchDet from '../components/Home/Small/SearchDet';
import Apidata from '../components/Home/Apidata';
import Alert1 from '../components/Home/Alert1';
import Alert2 from '../components/Home/Alert2';
import Alert4 from '../components/Home/Alert4';



class App extends Component {
    render() {
        return (
            
        
            <Router>
                <div>

                    <Route path="/formank" component={formank}/>
                    <Route exact path="/Home" component={Home}/>
                    <Route path="/MainPage/" component={MainPage}/>
                    <Route path="/MainPage1/:id" component={MainPage1} />       
                    <Route exact path="/" component={Home1}/>
                    <Route path="/Home2" component={Home2}/>
                    <Route path="/Maps" component={Maps}/>
                    <Route path="/Maps1" component={Maps1}/>
                    <Route path="/MainPage4" component={MainPage4} />  
                    <Route path="/MainPage5" component={MainPage5} />  
                    <Route path="/MainPage6" component={MainPage6} />      

                    <Route path="/Review" component={Review}/> 
                    <Route path="/Reviews" component={Reviews}/> 
                    <Route path="/Reviewnew" component={Reviewnew}/>
                    <Route path="/Reviewnaya" component={Reviewnaya}/>

                    <Route path="/cat/United States/:n3" component={PlacesGoUS}/>
                    <Route path="/cat/India/:n3" component={PlacesGoInd}/>





                    <Route path="/Search" component={Search}/> 
                    <Route path="/Geo" component={Geo}/> 
                    
                    <Route path="/abcd99/:n1" component={abcd99}/>
                    <Route path="/abcdin/:n3" component={abcdin}/>
                    <Route path="/abcdus/:n3" component={abcdus}/>
                   <Route path="/radiogaga" component={radiotest} />
                   <Route path="/radiogogo" component={radiotest1} />
                   <Route path="/Login" component={Login}/>
                   <Route path="/Logout" component={Logout}/>


                   <Route path="/Register" component={Register}/>
                    <Route path="/weatherme" component={weatherme}/>
                    <Route path="/Weatherme2" component={Weatherme2}/>
                   <Route path="/Abcd/:n3/:n2/:n1" component={Abcd}/>
                   <Route path="/states" component={states}/>
                   <Route path="/activity/:n2/:n1" component={Activity}/>
                   <Route path="/Allact" component={Allact}/>
                   <Route path="/Allfac" component={Allfac}/>

                   <Route path="/AllusNat" component={AllusNat}/>
                   <Route path="/AllindNat" component={AllusNat}/>

                   <Route path="/facility/:n2/:n1" component={Facility}/>

                   <Route path="/Surfing" component={Surfing}/>
                    <Route path="/FaraweRanch" component={FaraweRanch}/>
                    <Route path="/BeachVolleyball" component={BeachVolleyball}/>
                    <Route path="/Paragliding" component={Paragliding}/>
                    <Route path="/Skiing" component={Skiing}/>
                    <Route path="/Swimming" component={Swimming}/>
                    <Route path="/Fishing" component={Fishing}/>
                    <Route path="/GoKarting" component={GoKarting}/>
                    <Route path="/Camping" component={Camping}/>
                    <Route path="/HorseRiding" component={HorseRiding}/>
                    <Route path="/MuseumVisit" component={MuseumVisit}/>
                    <Route path="/SearchDet" component={SearchDet}/>

                    <Route path="/Contactus" component={Contactus}/>
                    <Route path="/apidata" component={Apidata}/>
                    <Route path="/Alert1" component={Alert1}/>
                    <Route path="/Alert2" component={Alert2}/>
                    <Route path="/Alert2" component={Alert2}/>
                    <Route path="/Alert4" component={Alert4}/>
                   




                    <Route  path="/Loginscreen" component={Loginscreen}/>
                    <Route path="/Gallery" component={Gallery1}/>

                    <Route path="/MainPage2/:id" render={props => <MainPage2 {...props} />} />  

                    <Route path="/India/Maharashtra/:id1" component={MainPage7}/>                               
                    <Route path="/India/Kerala/:id1" component= {MainPage7}/>
                    <Route path="/India/Karnataka/:id1" component= {MainPage7}/>
                    <Route path="/India/Bihar/:id1" component= {MainPage7}/>
                    <Route path="/India/Madhya Pradesh/:id1" component= {MainPage7}/>
                    <Route path="/India/Uttar Pradesh/:id1" component= {MainPage7}/>
                    <Route path="/India/Himachal Pradesh/:id1" component= {MainPage7}/>
                    <Route path="/India/Arunachal Pradesh/:id1" component= {MainPage7}/>
                    <Route path="/India/Rajasthan/:id1" component= {MainPage7}/>
                    <Route path="/India/Jammu and Kashmir/:id1" component= {MainPage7}/>
                    <Route path="/India/Punjab/:id1" component= {MainPage7}/>
                    <Route path="/India/Harayana/:id1" component= {MainPage7}/>
                    <Route path="/India/Telangana/:id1" component= {MainPage7}/>
                    <Route path="/India/Tamil Nadu/:id1" component= {MainPage7}/>
                    <Route path="/India/West Bengal/:id1" component= {MainPage7}/>
                    <Route path="/India/Sikkim/:id1" component= {MainPage7}/>
                    <Route path="/India/Assam/:id1" component= {MainPage7}/>
                    <Route path="/India/Manipur/:id1" component= {MainPage7}/>
                    <Route path="/India/Mizoram/:id1" component= {MainPage7}/>
                    <Route path="/India/Tripura/:id1" component= {MainPage7}/>
                    <Route path="/India/Goa/:id1" component= {MainPage7}/>
                    <Route path="/India/Amritsar/:id1" component= {MainPage7}/>
                    <Route path="/India/Himachal/:id1" component= {MainPage7}/>
                    <Route path="/India/Gujarat/:id1" component= {MainPage7}/>
                    <Route path="/India/Andaman and Nicobar Islands/:id1" component= {MainPage7}/>
                    <Route path="/India/Meghalaya/:id1" component= {MainPage7}/>
                    <Route path="/India/Jharkhand/:id1" component= {MainPage7}/>
                    <Route path="/India/Odisha/:id1" component= {MainPage7}/>
                    <Route path="/India/Uttarakhand/:id1" component= {MainPage7}/>
                    <Route path="/India/Chhattisgarh/:id1" component= {MainPage7}/>
                    <Route path="/India/Delhi/:id1" component= {MainPage7}/>

                    
                    <Route path="/United States/Alabama/:id1" component= {MainPage7}/>
                    <Route path="/United States/Alaska/:id1" component= {MainPage7}/>
                    <Route path="/United States/Arizona/:id1" component= {MainPage7}/>
                    <Route path="/United States/Arkansas/:id1" component= {MainPage7}/>
                    <Route path="/United States/California/:id1" component= {MainPage7}/>
                    <Route path="/United States/Colorado/:id1" component= {MainPage7}/>
                    <Route path="/United States/Connecticut/:id1" component= {MainPage7}/>
                    <Route path="/United States/Delaware/:id1" component= {MainPage7}/>
                    <Route path="/United States/Florida/:id1" component= {MainPage7}/>
                    <Route path="/United States/Georgia/:id1" component= {MainPage7}/>
                    <Route path="/United States/Hawaii/:id1" component= {MainPage7}/>
                    <Route path="/United States/Idaho/:id1" component= {MainPage7}/>
                    <Route path="/United States/Illinois/:id1" component= {MainPage7}/>
                    <Route path="/United States/Indiana/:id1" component= {MainPage7}/>
                    <Route path="/United States/Iowa/:id1" component= {MainPage7}/>
                    <Route path="/United States/Kansas/:id1" component= {MainPage7}/>
                    <Route path="/United States/Kentucky/:id1" component= {MainPage7}/>
                    <Route path="/United States/Lousiana/:id1" component= {MainPage7}/>
                    <Route path="/United States/Maine/:id1" component= {MainPage7}/>
                    <Route path="/United States/Maryland/:id1" component= {MainPage7}/>
                    <Route path="/United States/Massachusetts/:id1" component= {MainPage7}/>
                    <Route path="/United States/Michigan/:id1" component= {MainPage7}/>
                    <Route path="/United States/Minnesota/:id1" component= {MainPage7}/>
                    <Route path="/United States/Mississippi/:id1" component= {MainPage7}/>
                    <Route path="/United States/Missouri/:id1" component= {MainPage7}/>
                    <Route path="/United States/Montana/:id1" component= {MainPage7}/>
                    <Route path="/United States/Nebraska/:id1" component= {MainPage7}/>
                    <Route path="/United States/Nevada/:id1" component= {MainPage7}/>
                    <Route path="/United States/New Hampshire/:id1" component= {MainPage7}/>
                    <Route path="/United States/New Jersey/:id1" component= {MainPage7}/>
                    <Route path="/United States/New Mexico/:id1" component= {MainPage7}/>
                    <Route path="/United States/New York/:id1" component= {MainPage7}/>
                    <Route path="/United States/North Carolina/:id1" component= {MainPage7}/>
                    <Route path="/United States/North Dakota/:id1" component= {MainPage7}/>
                    <Route path="/United States/Ohio/:id1" component= {MainPage7}/>
                    <Route path="/United States/Oklahoma/:id1" component= {MainPage7}/>
                    <Route path="/United States/Oregon/:id1" component= {MainPage7}/>
                    <Route path="/United States/Pennsylvania/:id1" component= {MainPage7}/>
                    <Route path="/United States/Rhode Island/:id1" component= {MainPage7}/>
                    <Route path="/United States/South Carolina/:id1" component= {MainPage7}/>
                    <Route path="/United States/South Dakota/:id1" component= {MainPage7}/>
                    <Route path="/United States/Tennessee/:id1" component= {MainPage7}/>
                    <Route path="/United States/Texas/:id1" component= {MainPage7}/>
                    <Route path="/United States/Utah/:id1" component= {MainPage7}/>
                    <Route path="/United States/Vermont/:id1" component= {MainPage7}/>
                    <Route path="/United States/Virginia/:id1" component= {MainPage7}/>
                    <Route path="/United States/Washington/:id1" component= {MainPage7}/>
                    <Route path="/United States/West Virginia/:id1" component= {MainPage7}/>
                    <Route path="/United States/Wisconsin/:id1" component= {MainPage7}/>
                    <Route path="/United States/Wyoming/:id1" component= {MainPage7}/>
                    

                </div>
              
                </Router>
           
        
          
        );
    }
}

export default App;