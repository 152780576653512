import React from "react";
import {MDBBtn, MDBBtnGroup,MDBRow,MDBCol,MDBCard,MDBCardImage,MDBCardBody,MDBIcon,MDBCardTitle,MDBCardText,MDBCardGroup} from "mdbreact";
import {Card,CardImage,CardBody,CardTitle,CardText,Fa,CardGroup} from 'mdbreact';
import { Container, Row, Col, Input } from 'mdbreact';

import NBar from './NBar';
import { ButtonGroup,Button,DropdownButton,Dropdown} from 'react-bootstrap';
import {BrowserRouter as Router,Route,Link} from "react-router-dom";
import './abcd99.css';
class abcd99 extends React.Component{
	constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false
            //id2:this.props.match.params.id1
        }
    }
    componentDidMount() {
    	//const { match :{ params } } = this.props;
    	//let id2=this.props.match.params.id1;
    	const {n1} =this.props.match.params.n1;
        fetch(`https://emanjhaapi.com/locapi/alphabet/${this.props.match.params.n1}`)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json
                })
            });
    }


	render(){
		let n1=this.props.match.params.n1;
		var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading...</div>;
        }
		return(
			<div>
				 
               <Router>              
                <NBar/>
           		</Router>

           <div className="d-flex justify-content-center">
        <p> <h2>Find a Park</h2> </p>
         <br></br>
         
         </div>
         <div className="d-flex justify-content-center">
        <p> <h4>BY THE FIRST LETTER OF THE PARK'S NAME</h4> </p>
         <br></br>
         
         </div>
			
<div className="container-fluid butgrp">
			<MDBBtnGroup className="d-flex justify-content-center">
				<MDBBtn href="/abcd99/all" size="sm" classname="butalpha">@</MDBBtn>
			    <MDBBtn href="/abcd99/a" size="sm" classname="butalpha">a</MDBBtn>
			    <MDBBtn href="/abcd99/b" size="sm" className="butalpha">b</MDBBtn>		    
			    <MDBBtn href="/abcd99/c" size="sm" className="butalpha">c</MDBBtn>
			    <MDBBtn href="/abcd99/d" size="sm" className="butalpha">d</MDBBtn>
			    <MDBBtn href="/abcd99/e" size="sm" className="butalpha">e</MDBBtn>
			    <MDBBtn href="/abcd99/f" size="sm" className="butalpha">f</MDBBtn>
			    <MDBBtn href="/abcd99/g" size="sm" className="butalpha">g</MDBBtn>
			    <MDBBtn href="/abcd99/h" size="sm" className="butalpha">h</MDBBtn>
			    <MDBBtn href="/abcd99/i" size="sm" className="butalpha">i</MDBBtn>
			    <MDBBtn href="/abcd99/j" size="sm" className="butalpha">j</MDBBtn>
			    <MDBBtn href="/abcd99/k" size="sm" className="butalpha">k</MDBBtn>
			    <MDBBtn href="/abcd99/l" size="sm" className="butalpha">l</MDBBtn>
			    <MDBBtn href="/abcd99/m" size="sm" className="butalpha">m</MDBBtn>
			    <MDBBtn href="/abcd99/n" size="sm" className="butalpha">n</MDBBtn>
			    <MDBBtn href="/abcd99/o" size="sm" className="butalpha">o</MDBBtn>
			    <MDBBtn href="/abcd99/p" size="sm" className="butalpha">p</MDBBtn>
			    <MDBBtn href="/abcd99/q" size="sm" className="butalpha">q</MDBBtn>
			    <MDBBtn href="/abcd99/r" size="sm" className="butalpha">r</MDBBtn>
			    <MDBBtn href="/abcd99/s" size="sm" className="butalpha">s</MDBBtn>
			    <MDBBtn href="/abcd99/t" size="sm" className="butalpha">t</MDBBtn>
			    <MDBBtn href="/abcd99/u" size="sm" className="butalpha">u</MDBBtn>
			    <MDBBtn href="/abcd99/v" size="sm" className="butalpha">v</MDBBtn>
			    <MDBBtn href="/abcd99/w" size="sm" className="butalpha">w</MDBBtn>
			    <MDBBtn href="/abcd99/x" size="sm" className="butalpha">x</MDBBtn>
			    <MDBBtn href="/abcd99/y" size="sm" className="butalpha">y</MDBBtn>
			    <MDBBtn href="/abcd99/z" size="sm" className="butalpha">z</MDBBtn>
  			</MDBBtnGroup>
  	</div>
  			<br/><br/>
<div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
  <div className="btn-group btn-group-sm m1-2 flex-wrap" role="group" aria-label="First group">
    <button type="button" class="btn btn-secondary ">a</button>
    <button type="button" class="btn btn-secondary">b</button>
    <button type="button" class="btn btn-secondary">c</button>
    <button type="button" class="btn btn-secondary">d</button>
    <button type="button" class="btn btn-secondary">e</button>
    <button type="button" class="btn btn-secondary">f</button>
    <button type="button" class="btn btn-secondary">g</button>
    <button type="button" class="btn btn-secondary">h</button>
    <button type="button" class="btn btn-secondary">i</button>
    <button type="button" class="btn btn-secondary">j</button>
    <button type="button" class="btn btn-secondary">k</button>
    <button type="button" class="btn btn-secondary">l</button>
    <button type="button" class="btn btn-secondary">m</button>
    <button type="button" class="btn btn-secondary">n</button>
    <button type="button" class="btn btn-secondary">o</button>
    <button type="button" class="btn btn-secondary">p</button>
    <button type="button" class="btn btn-secondary">q</button>
    <button type="button" class="btn btn-secondary">r</button>
    <button type="button" class="btn btn-secondary">s</button>
    <button type="button" class="btn btn-secondary">t</button>
    <button type="button" class="btn btn-secondary">u</button>
    <button type="button" class="btn btn-secondary">v</button>
    <button type="button" class="btn btn-secondary">w</button>
    <button type="button" class="btn btn-secondary">x</button>
    <button type="button" class="btn btn-secondary">y</button>
    <button type="button" class="btn btn-secondary">z</button>
    <button type="button" class="btn btn-secondary">p</button>
  </div>
</div>
<div className="container-fluid">
<ul className="mumbai pagination justify-content-center">
    <li className="zombie border border-dark "><a className="pitch" href="#">all</a></li>
    <li className="zombie border border-dark "><a href="#">a</a></li>
    <li className="zombie border border-dark "><a href="#">b</a></li>
    <li className="zombie border border-dark "><a href="#">c</a></li>
    <li className="zombie border border-dark "><a href="#">d</a></li>
    <li className="zombie border border-dark "><a href="#">e</a></li>
    <li className="zombie border border-dark "><a href="#">f</a></li>
    <li className="zombie border border-dark "><a href="#">g</a></li>
    <li className="zombie border border-dark "><a href="#">h</a></li>
    <li className="zombie border border-dark "><a href="#">i</a></li>
    <li className="zombie border border-dark "><a href="#">j</a></li>
    <li className="zombie border border-dark "><a href="#">k</a></li>
    <li className="zombie border border-dark "><a href="#">l</a></li>
    <li className="zombie border border-dark "><a href="#">m</a></li>
    <li className="zombie border border-dark "><a href="#">n</a></li>
   </ul>
   <ul className="mumbai pagination justify-content-center">
    <li className="zombie border border-dark "><a href="#">o</a></li>
    <li className="zombie border border-dark "><a href="#">p</a></li>
    <li className="zombie border border-dark "><a href="#">q</a></li>
    <li className="zombie border border-dark "><a href="#">r</a></li>
    <li className="zombie border border-dark "><a href="#">s</a></li>
    <li className="zombie border border-dark "><a href="#">t</a></li>
    <li className="zombie border border-dark "><a href="#">u</a></li>
    <li className="zombie border border-dark "><a href="#">v</a></li>
    <li className="zombie border border-dark "><a href="#">w</a></li>
    <li className="zombie border border-dark "><a href="#">x</a></li>
    <li className="zombie border border-dark "><a href="#">y</a></li>
    <li className="zombie border border-dark "><a href="#">z</a></li>
  </ul>
</div>



<div className="ShowOnMob">
  <ButtonGroup>
  <DropdownButton as={ButtonGroup} title="click me" id="bg-nested-dropdown">
    <Dropdown.Item>a</Dropdown.Item>
    <Dropdown.Item>b</Dropdown.Item>
    <Dropdown.Item>c</Dropdown.Item>
    <Dropdown.Item>d</Dropdown.Item>
    <Dropdown.Item>e</Dropdown.Item>
    <Dropdown.Item>f</Dropdown.Item>
    <Dropdown.Item>g</Dropdown.Item>
    <Dropdown.Item>h</Dropdown.Item>
    <Dropdown.Item>i</Dropdown.Item>
    <Dropdown.Item>j</Dropdown.Item>
    <Dropdown.Item>k</Dropdown.Item>
    <Dropdown.Item>l</Dropdown.Item>
    <Dropdown.Item>m</Dropdown.Item>
    <Dropdown.Item>n</Dropdown.Item>
    <Dropdown.Item>o</Dropdown.Item>
    <Dropdown.Item>p</Dropdown.Item>
    <Dropdown.Item>q</Dropdown.Item>
    <Dropdown.Item>r</Dropdown.Item>
    <Dropdown.Item>s</Dropdown.Item>
    <Dropdown.Item>t</Dropdown.Item>
    <Dropdown.Item>u</Dropdown.Item>
    <Dropdown.Item>v</Dropdown.Item>
    <Dropdown.Item>w</Dropdown.Item>
    <Dropdown.Item>x</Dropdown.Item>
    <Dropdown.Item>y</Dropdown.Item>
    <Dropdown.Item>z</Dropdown.Item>
  </DropdownButton>
</ButtonGroup>
</div>




  			<br/>
  <Row className="onemore">
    {items.map(item=> (

			<Col className="mt-4 needmoney">
          <Card className="mx-auto" style={{ width: "16rem", height:"13rem" }}>
            <CardImage className="img-fluid" src={item.location_images.fimg} waves/>
            <CardBody  className="elegant-color white-text">
              <CardTitle>
                <a className="white-text" href={`/${item.country}/${item.state}/7`}>{item.location_name}</a>
              </CardTitle>
            </CardBody>
          </Card>
          <br/>
          <br/>
       </Col> 
       ))}
   </Row>
			












      <ul>
				{items.map(item => (
					<li key={item.location_id}>
					<a>{item.location_name}</a>
						</li>))}
			</ul>

			<Row>
        <Col>
          <Card style={{ maxWidth: "20rem" }} >
            <CardImage src={items[0].location_images.fimg}/>
            <CardBody className="elegant-color white-text">
                <CardTitle><a>Nishants Natonal Park </a></CardTitle>
                <hr className="hr-light" />
                <CardText className="white-text">Mumbai, <br/>Maharashtra
                </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>

<br/><br/>
 <MDBRow>
      <MDBCol style={{ maxWidth: "22rem" }}>
        <MDBCard>
          <MDBCardImage top src={items[0].location_images.fimg} overlay="white-slight"
            hover waves alt="MDBCard image cap" />
          <MDBCardBody className="elegant-color white-text rounded-bottom">  
            <a href="#!" className="activator waves-effect waves-light mr-4">
              <MDBIcon icon="share-alt" />
            </a>
            <MDBCardTitle>MDBCard Title</MDBCardTitle>
            <hr className="hr-light" />
            <MDBCardText className="white-text">
              Some quick example text to build on the card title and make
              up the bulk of the card&apos;s content.
            </MDBCardText>
            <a href="#!" className="black-text d-flex justify-content-end">
              <h5 className="white-text">
                Read more
                <MDBIcon icon="angle-double-right" />
              </h5>
            </a>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
			

			</div>




			);
	}
}

export default abcd99