import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Button } from 'mdbreact';
import {MDBContainer,MDBCol,MDBRow,Fa} from 'mdbreact';
import {Card,CardImage,CardBody,CardTitle,CardText} from 'mdbreact';
import { BrowserRouter as Router,Route } from 'react-router-dom';
import { Container, Row, Col, Input } from 'mdbreact';
import './Home.css';
import Video from './video';
import ModalPage from './ModalPage';
import Footer from './Footer';
import Alert from './Alert';



class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: true,
        };
    this.onClick = this.onClick.bind(this);
    }

    onClick(){
        this.setState({
            collapse: !this.state.collapse,
        });
    }
    render() {
        return (
           
            <div style={{backgroundColor:'#f5f5f5'}}>
                <Router>
              
              <Navbar style={{backgroundColor:'#2E2E2E'}} dark expand="md" scrolling>
                  <NavbarBrand href="/">
                      <strong>Emanjha.net</strong>
                  </NavbarBrand>
                  { !this.state.isWideEnough && <NavbarToggler onClick = { this.onClick } />}
                  <Collapse isOpen = { this.state.collapse } navbar>
                      <NavbarNav left>
                        <NavItem active>
                            <NavLink to="#">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="#">About</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="#">Location</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="#">Sign in</NavLink>
                        </NavItem>
                        <NavItem Right>
                            <Alert/>
                        </NavItem>
                      </NavbarNav>
                      
                  </Collapse>
              </Navbar>
          </Router>
        <br/><br/>
        <h1 className="text-center"  style={{fontFamily:'comic sans ms',fontWeight:'bold'}}>Emanjha</h1>
        <Col md="6" className="mx-auto" >
            <form className="form-inline mt-4 mb-4">
            <Fa icon="search" />
            <input
                className="form-control form-control-sm ml-3 w-75"
                type="text"
                placeholder="Search"
                aria-label="Search"
            />
            </form>
        </Col>
        <MDBContainer  className="mw-100  ">
            <MDBRow className=" ">      
                <MDBCol className="row1 " size="9" lg="3">
                <form>
    <p className="h4 text-center mb-4">Sign in</p>
    <label htmlFor="defaultFormLoginEmailEx" className="grey-text">Your email</label>
    <input type="email" id="defaultFormLoginEmailEx" className="form-control"/>
    <br/>
    <label htmlFor="defaultFormLoginPasswordEx" className="grey-text">Your password</label>
    <input type="password" id="defaultFormLoginPasswordEx" className="form-control"/>
    <div className="text-center mt-4">
    <button className="btn btn-indigo" type="submit">Login</button>
    </div>
</form>
      
     
                </MDBCol>
                <MDBCol className="row1 " size="9" sm="6" lg="8"><p style={{fontSize:'25px',fontWeight:'normal'}}>Top Locations Near You</p>
                        <Row>
                        <Col>
                            <Card style={{ width: "16rem" }}>
                            <CardImage
                                className="img-fluid" src="assets/home/5.png" waves style={{height:'13vw'}}/>
                            <CardBody>
                                <CardTitle>Alleppey Beach</CardTitle>
                                
                               
                            </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: "16rem" }}>
                            <CardImage
                                className="img-fluid "
                                src="assets/home/1.png" 
                                style={{height:'13vw',objectFit:'cover'}}
                                waves
                            />
                            <CardBody>
                                <CardTitle>Idmalayavar Dam</CardTitle>
                                
                                
                            </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: "16rem" }}>
                            <CardImage
                                className="img-fluid"
                                src="assets/home/2.png" 
                                style={{height:'13vw',objectFit:'cover'}}
                                waves
                            />
                            <CardBody>
                                <CardTitle>Idduki Dam</CardTitle>
                                
                                
                            </CardBody>
                            </Card>
                        </Col>
                </Row>
                <br/>
                <Row>
                        <Col>
                            <Card style={{ width: "16rem" }}>
                            <CardImage
                                className="img-fluid"
                                src="assets/home/3.png" 
                                style={{height:'13vw',objectFit:'cover'}}
                                waves
                            />
                            <CardBody>
                                <CardTitle>Sabarimala Temple</CardTitle>
                                
                               
                            </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: "16rem" }}>
                            <CardImage
                                src="assets/home/4.png" 
                                style={{height:'13vw',objectFit:'cover'}}
                                waves
                            />
                            <CardBody>
                                <CardTitle>KayamKulam Lake</CardTitle>
                                
                                
                            </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: "16rem" }}>
                            <CardImage
                                src="assets/home/6.png" 
                                style={{height:'13vw',objectFit:'cover'}}
                                waves
                            />
                            <CardBody>
                                <CardTitle>Nagaraja Temple</CardTitle>
                                
                                
                            </CardBody>
                            </Card>
                        </Col>
                </Row>
                

                </MDBCol>
             </MDBRow>
            
         </MDBContainer>

<Video/>
    
        

       
    
<ModalPage/>
<Footer/>
      
   


 </div>
            
        );
    }
}

export default Home;