import React,{Component} from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import NBar from '../components/Home/Small/NBar';
import FooterPagePro from './FooterPagePro';
import './states.css';

class states extends Component{
	render(){
		return(
			<div>
				<NBar/>
				<br/>
				<center>
				<div className="chaapchaap">
				<MDBTable small striped >
			      <MDBTableHead color="black" textWhite>
			        <tr>
			          <th>#</th>
			          <th>USA</th>
			          <th>India</th>			          
			        </tr>
			      </MDBTableHead>
			      <MDBTableBody>
			        <tr>
			   		<td>1</td>
			          <td><a href="Abcd/United States/Alabama/a">Alabama</a></td>
			          <td><a href="Abcd/India/Andra Pradesh/a">Andra Pradesh</a></td>
			          
			        </tr>
			        <tr>
			          <td>2</td>
			          <td><a href="Abcd/United States/Alaska/a">Alaska</a></td>
			          <td><a href="Abcd/India/Arunachal Pradesh/a">Arunachal Pradesh</a></td>
			         
			        </tr>
			        <tr>
			          <td>3</td>
			          <td><a href="Abcd/United States/Arizona/a">Arizona</a></td>
			          <td><a href="Abcd/India/Assam/a">Assam</a></td>			          
			        </tr>
			        <tr>
			        	<td>4</td>
			        	<td><a href="Abcd/United States/Arkansas/a">Arkansas</a></td>
			        	<td><a href="Abcd/India/Bihar/a">Bihar</a></td>
			        </tr>
			        <tr>
			        	<td>5</td>
			        	<td><a href="Abcd/United States/California/a">California</a></td>
			        	<td><a href="Abcd/India/Chattisgarh/a">Chattisgarh</a></td>
			        </tr>
			        <tr>
			        	<td>6</td>
			        	<td><a href="Abcd/United States/Colorado/a">Colorado</a></td>
			        	<td><a href="Abcd/India/Goa/a">Goa</a></td>
			        </tr>
			        <tr>
			        	<td>7</td>
			        	<td><a href="Abcd/United States/Connecticut/a">Connecticut</a></td>
			        	<td><a href="Abcd/India/Gujarat/a">Gujurat</a></td>
			        </tr>
			        <tr>
			        	<td>8</td>
			        	<td><a href="Abcd/United States/Delaware/a">Delaware</a></td>
			        	<td><a href="Abcd/India/Haryana/a">Haryana</a></td>
			        </tr>
			        <tr>
			        	<td>9</td>
			        	<td><a href="Abcd/United States/Florida/a">Florida</a></td>
			        	<td><a href="Abcd/India/Himachal Pradesh/a">Himachal Pradesh</a></td>
			        </tr>
			        <tr>
			        	<td>10</td>
			        	<td><a href="Abcd/United States/georgia/a">Georgia</a></td>
			        	<td><a href="Abcd/India/Jammu and Kashmir/a">Jammu and Kashmir</a></td>
			        </tr>
			        <tr>
			        	<td>11</td>
			        	<td><a href="Abcd/United States/Hawaii/a">Hawaii</a></td>
			        	<td><a href="Abcd/India/Jharkhand/a">Jharkhand</a></td>
			        </tr>
			        <tr>
			        	<td>12</td>
			        	<td><a href="Abcd/United States/Idaho/a">Idaho</a></td>
			        	<td><a href="Abcd/India/Karnataka/a">Karnatak</a></td>
			        </tr>
			        <tr>
			        	<td>13</td>
			        	<td><a href="Abcd/United States/Illinois/a">Illinois</a></td>
			        	<td><a href="Abcd/India/Kerala/a">Kerala</a></td>
			        </tr>
			        <tr>
			        	<td>14</td>
			        	<td><a href="Abcd/United States/Indiana/a">Indiana</a></td>
			        	<td><a href="Abcd/India/Madhya Pradesh/a">Madhya Pradesh</a></td>
			        </tr>
			        <tr>
			        	<td>15</td>
			        	<td><a href="Abcd/United States/Iowa/a">Iowa</a></td>
			        	<td><a href="Abcd/India/Maharashtra/a">Maharashtra</a></td>
			        </tr>
			        <tr>
			        	<td>16</td>
			        	<td><a href="Abcd/United States/Kansas/a">Kansas</a></td>
			        	<td><a href="Abcd/India/Manipur/a">Manipur</a></td>
			        </tr>
			        <tr>
			        	<td>17</td>
			        	<td><a href="Abcd/United States/Kentucky/a">Kentucky</a></td>
			        	<td><a href="Abcd/India/Mizoram/a">Mizoram</a></td>
			        </tr>
			        <tr>
			        	<td>18</td>
			        	<td><a href="Abcd/United States/Louisiana/a">Louisiana</a></td>
			        	<td><a href="Abcd/India/Meghalaya/a">Meghalaya</a></td>
			        </tr>

			        <tr>
			        	<td>19</td>
			        	<td><a href="Abcd/United States/Maine/a">Maine</a></td>
			        	<td><a href="Abcd/India/Nagaland/a">Nagaland</a></td>
			        </tr>
			        <tr>
			        	<td>20</td>
			        	<td><a href="Abcd/United States/Maryland/a">Maryland</a></td>
			        	<td><a href="Abcd/India/Odisha/a">Odisha</a></td>
			        </tr>
			        <tr>
			        	<td>21</td>
			        	<td><a href="Abcd/United States/Massachusetts/a">Massachusetts</a></td>
			        	<td><a href="Abcd/India/Punjab/a">Punjab</a></td>
			        </tr>
			        <tr>
			        	<td>22</td>
			        	<td><a href="Abcd/United States/Michigan/a">Michigan</a></td>
			        	<td><a href="Abcd/india/Rajastan/d">Rajasthan</a></td>
			        </tr>
			        <tr>
			        	<td>23</td>
			        	<td><a href="Abcd/United States/Minnesota/a">Minnesota</a></td>
			        	<td><a href="Abcd/India/Sikkim/a">Sikkim</a></td>
			        </tr>
			        <tr>
			        	<td>24</td>
			        	<td><a href="Abcd/United States/Mississippi/a">Mississippi</a></td>
			        	<td><a href="Abcd/India/Tamil Nadu/a">Tamil Nadu</a></td>
			        </tr>
			        <tr>
			        	<td>25</td>
			        	<td><a href="Abcd/United States/Missouri/a">Missouri</a></td>
			        	<td><a href="Abcd/India/Telangana/a">Telangana</a></td>
			        </tr>
			        <tr>
			        	<td>26</td>
			        	<td><a href="Abcd/United States/montana/a">Montana</a></td>
			        	<td><a href="Abcd/India/Tripura/a">Tripura</a></td>
			        </tr>
			        <tr>
			        	<td>27</td>
			        	<td><a href="Abcd/United States/Nebraska/a">Nebraska</a></td>
			        	<td><a href="Abcd/India/Uttar Pradesh/a">UP</a></td>
			        </tr>
			        <tr>
			        	<td>28</td>
			        	<td><a href="Abcd/United States/Nevada/a">Nevada</a></td>
			        	<td ><a href="Abcd/India/Uttarakhand/a">Uttarakhand</a></td>
			        </tr>
			        <tr>
			        	<td>29</td>
			        	<td><a href="Abcd/United States/New Hampshire/a">New Hampshire</a></td>
			        	<td><a href="Abcd/India/West Bengal/a">West Bengal</a></td>
			        </tr>
			        <tr>
			        	<td>30</td>
			        	<td><a href="Abcd/United States/New Jersey/a">New Jersey</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>31</td>
			        	<td><a href="Abcd/United States/New Mexico/a">New Mexico</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>32</td>
			        	<td><a href="Abcd/United States/New York/a">New York</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>33</td>
			        	<td><a href="Abcd/United States/North Calorina/a">North Calorina</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>34</td>
			        	<td><a href="Abcd/United States/North Dakota/a">North Dakota</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>35</td>
			        	<td><a href="Abcd/United States/Ohio/a">Ohio</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>36</td>
			        	<td><a href="Abcd/United States/Oklahoma/a">Oklahoma</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>37</td>
			        	<td><a href="Abcd/United States/Oregon/a">Oregon</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>38</td>
			        	<td><a href="Abcd/United States/Pennsylvania/a">Pennsylvania</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>39</td>
			        	<td><a href="Abcd/United States/Rhode Island/a">Rhode Island</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>40</td>
			        	<td><a href="Abcd/United States/South Carolina/a">South Carolina</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>41</td>
			        	<td><a href="Abcd/United States/South Dakota/a">South Dakota</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>42</td>
			        	<td><a href="Abcd/United States/Tennessee/a">Tennessee</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>43</td>
			        	<td><a href="Abcd/United States/Texas/a">Texas</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>44</td>
			        	<td><a href="Abcd/United States/Utah/a">Utah</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>45</td>
			        	<td><a href="Abcd/United States/Vermont/a">Vermont</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>46</td>
			        	<td><a href="Abcd/United States/Virginia/a">Virginia</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>47</td>
			        	<td><a href="Abcd/United States/washington/a">Washington</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>48</td>
			        	<td><a href="Abcd/United States/West Virginia/a">West Virginia</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>49</td>
			        	<td><a href="Abcd/United States/Wisconsin/a">Wisconsin</a></td>
			        	<td></td>
			        </tr>
			        <tr>
			        	<td>50</td>
			        	<td><a href="Abcd/United States/Wyoming/a">Wyoming</a></td>
			        	<td></td>
			        </tr>
			      </MDBTableBody>
			    </MDBTable>
			    </div>
			    </center>
			    <FooterPagePro/>
			</div>
			)

		}
}
export default states