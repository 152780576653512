import React, { Component } from 'react';
import {Navbar,NavbarBrand} from 'mdbreact';
import {Card,CardImage,CardBody,CardTitle,CardText,Row,Col} from 'mdbreact';
import './Home1.css'
class Home2 extends Component {
    render() {
        return (  
            <div>
                <body>
        <nav class="navbar navbar-expand-lg my-nav">
  <a class="navbar-brand" href="#">Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Pricing</a>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
  </div>
</nav>


<div class="video-header wrap">
	<div class="fullscreen-video-wrap">
		<video src="assets/bgvid.mp4" autoplay loop></video>
	</div>
	<div class="header-overlay">
		<div class="header-content">
			<h1>GOOD MORNING</h1>
			<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit hic eaque rerum cupiditate officiis recusandae iusto quaerat architecto odio illum.</p>
			<a class="btn btn-success my-btn mt-4">KNOW MORE &gt;</a>
		</div>
	</div>
</div>

<section class="section section-a">
	<div class="container">
		<h1>THANK FOR WATCHING THIS VIDEO.</h1>
		<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo ducimus cum accusantium illum quis, facilis dolorem. Aliquid alias illo magnam inventore possimus quibusdam, natus, tempora eligendi ipsam maxime, quis laborum incidunt voluptates mollitia cumque sed. Quisquam ullam incidunt id obcaecati consequatur aliquam, molestias, eveniet atque, dolor similique iure, vitae commodi corporis? Error tempore nemo, distinctio? Deserunt repellat ex quo, nesciunt iusto provident iure, laudantium molestias, adipisci quis impedit? Excepturi, repellat.</p>
	</div>
</section>


</body>  
            </div>
              );
    }
}

export default Home2;